import { API } from 'aws-amplify'; // AI-GEN - Cursor - GPT4

export interface unblockRecommendationRequest {
  student_id: string;
  recommendation_id: string;
  subject: string;
  message: string;
  type: 'support' | 'unblock';
}

export interface UnblockRecommendationResult {
  message: string;
}

export async function unblockRecommendation(
  input: unblockRecommendationRequest
): Promise<UnblockRecommendationResult> {
  try {
    const apiName = process.env.NEXT_PUBLIC_DASH_API_NAME!;
    const path = `api/recommendations/unblock`;
    const requestParameters = {
      queryStringParameters: {},
      body: input,
    };
    const response = await API.post(apiName, path, requestParameters);
    if (!response || !response.result) {
      return response;
    }
    return response.result;
  } catch (error) {
    console.error(error);
    if (error.response && error.response.data) {
      throw new Error(error.response.data.message || 'An error occurred');
    }
    throw error;
  }
}
