import { API } from '@aws-amplify/api';

interface CreateUpdateUserInput {
  email: string;
  name: string;
}

export async function createUpdateUser(
  input: CreateUpdateUserInput,
  isEdit: boolean
): Promise<void> {
  try {
    console.log('update user', input);
    console.log('edit mode', isEdit);

    const isEditString = isEdit ? 'true' : 'false';
    const apiName = process.env.NEXT_PUBLIC_DASH_API_NAME!;
    const path = 'create-update-user';
    const myInit = {
      queryStringParameters: { isEdit: isEditString },
      body: input,
    };

    const response = await API.post(apiName, path, myInit);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
