import { API } from 'aws-amplify'; // AI-GEN - Cursor - GPT4

export interface Timeframe {
  id: string;
  title: string;
}

export interface Timeframes {
  timeframes: Timeframe[];
}

export interface TimeframesOutput {
  timeframes: Timeframes;
}

export async function getTimeframes(): Promise<TimeframesOutput> {
  // AI-GEN - Cursor - GPT4
  try {
    // AI-GEN - Cursor - GPT4
    const apiName = process.env.NEXT_PUBLIC_DASH_API_NAME!; // AI-GEN - Cursor - GPT4
    const path = `api/goals/get-timeframes`; // AI-GEN - Cursor - GPT4
    const myInit = {
      // AI-GEN - Cursor - GPT4
      headers: {}, // AI-GEN - Cursor - GPT4
      queryStringParameters: {}, // AI-GEN - Cursor - GPT4
    }; // AI-GEN - Cursor - GPT4
    return await API.get(apiName, path, myInit); // AI-GEN - Cursor - GPT4
  } catch (error) {
    // AI-GEN - Cursor - GPT4
    console.error('Error fetching timeframes:', error); // AI-GEN - Cursor - GPT4
    throw error; // AI-GEN - Cursor - GPT4
  } // AI-GEN - Cursor - GPT4
} // AI-GEN - Cursor - GPT4
