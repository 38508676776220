import { API } from 'aws-amplify'; // AI-GEN - Cursor - GPT4

export interface GoalInput {
  studentId: string; // AI-GEN - Cursor - GPT4
  subject: string; // AI-GEN - Cursor - GPT4
} // AI-GEN - Cursor - GPT4

export async function invalidateGoal(goalInput: GoalInput): Promise<void> {
  try {
    const apiName = process.env.NEXT_PUBLIC_DASH_API_NAME!; // AI-GEN - Cursor - GPT4
    const path = `api/goals/invalidate-goal`; // AI-GEN - Cursor - GPT4
    const myInit = {
      headers: {}, // AI-GEN - Cursor - GPT4
      body: goalInput, // AI-GEN - Cursor - GPT4
    }; // AI-GEN - Cursor - GPT4
    await API.post(apiName, path, myInit); // AI-GEN - Cursor - GPT4
  } catch (error) {
    // AI-GEN - Cursor - GPT4
    console.error('Error invalidating goal:', error); // AI-GEN - Cursor - GPT4
    throw error; // AI-GEN - Cursor - GPT4
  } // AI-GEN - Cursor - GPT4
} // AI-GEN - Cursor - GPT4
