import { API } from '@aws-amplify/api';
import { TestCoachingForm } from 'common/@types/test-coaching';

export async function submitPostCoachingForm(input: TestCoachingForm): Promise<any> {
  try {
    const apiName = process.env.NEXT_PUBLIC_DASH_API_NAME!;
    const path = `coaching/submit-test-coaching-form`;
    const myInit = {
      body: {
        ...input,
      },
    };
    const response = await API.post(apiName, path, myInit);
    return response.data;
  } catch (error) {
    console.error('Error in submitPostCoachingForm:', error, 'Input:', input);
    throw error;
  }
}
