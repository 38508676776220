// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, List, ListSubheader } from '@mui/material';
// hooks
import useLocales from '../../../hooks/useLocales';
// type
import { NavSectionProps } from '@common/@types/nav';
//
import { NavListRoot } from './NavList';
import useAuth from '../../../hooks/useAuth';
import useSiteConfig from '../../../hooks/useSiteConfig';
import { useEffect, useState } from 'react';

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled(ListSubheader)(({ theme }) => ({
  ...theme.typography.overline,
  paddingTop: theme.spacing(3),
  paddingLeft: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  color: theme.palette.text.primary,
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

export default function NavSectionVertical({
  navConfig,
  isCollapse = false,
  currentView,
  ...other
}: NavSectionProps) {
  const { translate } = useLocales();
  const { user } = useAuth();
  const { isPageBlacklisted } = useSiteConfig();
  const [isCollapseMode, setCollapseMode] = useState(isCollapse);
  const theme = useTheme();

  // workaround to ensure the text is only displayed after the navbar open transition finishes,
  // to prevent layout shift while fitting labels in narrow width nav
  useEffect(() => {
    const delay = isCollapse ? 0 : theme.transitions.duration.short;
    setTimeout(() => setCollapseMode(isCollapse), delay);
  }, [isCollapse]);

  const decoratedNavConfig = navConfig
    ? navConfig.map((group) => {
        const blacklistedItems: boolean[] = [];
        group.items.forEach((item) => {
          let isItemBlacklisted = false; // Default to open
          if (!user) isItemBlacklisted = true; // No user (should only happen on `yarn export`) - blacklisted
          if (item && item.blacklistFunction) {
            // Otherwise, if we have a blacklistFunction, compute it
            isItemBlacklisted = item.blacklistFunction(user);
          }

          // And check to see if it's blacklisted by the site config
          isItemBlacklisted = isItemBlacklisted || isPageBlacklisted(item.path);
          item.blacklisted = isItemBlacklisted;

          blacklistedItems.push(isItemBlacklisted);
        });
        const visible = !blacklistedItems.every((item) => item === true);
        return {
          ...group,
          visible,
        };
      })
    : [];

  return (
    <Box {...other}>
      {decoratedNavConfig.map(
        (group) =>
          group.visible && (
            <List key={group.subheader} disablePadding sx={{ px: 2 }}>
              <ListSubheaderStyle
                disableSticky
                disableGutters
                sx={{
                  ...(isCollapseMode && {
                    opacity: 0,
                  }),
                  // Hides list subheaders for which all children are hidden/blacklisted
                  '&:last-child': {
                    display: 'none',
                  },
                }}
              >
                {isCollapseMode ? <span> &nbsp; </span> : translate(group.subheader)}
              </ListSubheaderStyle>

              {group.items.map((list) => (
                <NavListRoot
                  currentView={currentView}
                  key={list.title + list.path}
                  list={list}
                  isCollapse={isCollapse}
                />
              ))}
            </List>
          )
      )}
    </Box>
  );
}
