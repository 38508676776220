import { API } from '@aws-amplify/api';
import { type StudentLearningTime } from '../../@types/learning_time';

const getSubjectInfoFromAPI = async (date: string, subject: string, studentId: string) => {
  const apiName = process.env.NEXT_PUBLIC_DASH_API_NAME!;
  const path = '/learning-app-time';

  const myInit = {
    headers: {},
    queryStringParameters: {
      date,
      subject,
      student_id: studentId,
    },
  };

  const response = await API.get(apiName, path, myInit);
  const data = response?.result?.data?.json ?? [];

  return {
    subject,
    data,
  };
};

export async function getStudentLearningTime({
  date,
  subjects,
  studentId,
}: {
  date: string;
  subjects: string[];
  studentId: string;
}): Promise<Record<string, StudentLearningTime[]>> {
  if (!studentId || !subjects || !subjects.length) return {};

  try {
    const promises = subjects.map((subject) => getSubjectInfoFromAPI(date, subject, studentId));
    const results = await Promise.all(promises);

    const subjectInfoMap: Record<string, StudentLearningTime[]> = {};

    results.forEach((result) => {
      subjectInfoMap[result.subject] = result.data;
    });

    return subjectInfoMap;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
