import { API } from '@aws-amplify/api';
import { GetCoachingURLOutput } from '../../@types/coaching';

export async function getCoachingUrls({
  coachingId,
}: {
  coachingId: string;
}): Promise<GetCoachingURLOutput> {
  try {
    const apiName = process.env.NEXT_PUBLIC_DASH_API_NAME!;
    const path = 'get-coaching-urls';
    const myInit = {
      queryStringParameters: { coachingId: coachingId },
    };
    const response = await API.get(apiName, path, myInit);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
