import { pollAmplifyAPI } from './pollAmplifyAPI';

export interface EdulasticAssignmentRequest {
  studentId: string;
  subject: string;
  subject_id: number;
  test: string;
  grade_level: string;
  coach_url: string;
}

export interface EdulasticAssignmentResult {
  student_id?: number;
  priority: string;
  source_id: number;
  subject_id?: number;
  name: string;
  apiStatus: 'created' | 'updated' | 'error';
  uuid?: string;
  error?: string;
}

export async function assignEdulasticTest(
  input: EdulasticAssignmentRequest
): Promise<EdulasticAssignmentResult> {
  try {
    const apiName = process.env.NEXT_PUBLIC_ACADEMICS_API_NAME!;
    const path = 'api/testing/assign-edulastic-test';
    const requestParameters = {
      queryStringParameters: {},
      body: input,
    };

    const response = await pollAmplifyAPI('post', apiName, path, requestParameters);
    if (!response || !response.result) {
      return response;
    }
    return response.result;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
