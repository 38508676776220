import { API } from '@aws-amplify/api';
import { TestCoachingForm } from 'common/@types/test-coaching';

export async function getPostCoachingFormById({
  id,
}: {
  id: string;
}): Promise<TestCoachingForm | string> {
  try {
    const apiName = process.env.NEXT_PUBLIC_ACADEMICS_API_NAME!;
    const path = `api/coaching/test-coaching-form-data/${id}`;
    const myInit = {};
    const response = await API.get(apiName, path, myInit);
    return response?.result || 'not-found';
  } catch (error) {
    console.error(error);
    throw error;
  }
}
