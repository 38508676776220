import { API } from '@aws-amplify/api';

export type RealtimeDailyActivityItem = {
  date: string;
  student: string;
  campus_and_student_id: string;
  subject: string;
  app: string;
  course: string;
  correct: number;
  perc_questions_correct: number;
  minutes_working: number;
  total: number;
  has_antipattern: boolean;
  has_edulastic: string;
  id: string;
  mastered_levels: string;
  student_id: string;
  anti_pattern_count: string;
  target_master_units: string;
  correct_questions_per_hour: number;
  is_2hr_session: string;
  is_above_mastery_threshold: string;
  is_above_accuracy_threshold: boolean;
  is_above_mins_threshold: boolean;
};

interface RealtimeDailyActivityCacheEntry {
  expiry: number;
  data: RealtimeDailyActivityItem[];
}

interface Cache {
  [key: string]: RealtimeDailyActivityCacheEntry;
}

// In memory cache just for this api
let cache: Cache | null = null;

const cacheExpiry = 1000 * 60 * 2; // 2 minutes in milliseconds

export async function getRealtimeDailyActivity({
  startDate,
  endDate,
  students,
  alphaLevel,
}: {
  startDate: string | undefined;
  endDate: string | undefined;
  students: string[];
  alphaLevel: string[];
}): Promise<RealtimeDailyActivityItem[]> {
  try {
    const cacheKey = `${startDate}|${endDate}|${students.join(',')}|${alphaLevel.join(',')}`;
    // Initialize cache if not already done
    if (!cache) {
      cache = {};
    }

    // Clean expired cache entries
    invalidateExpiredCache();

    // Check if the data is already in the cache and not expired
    const now = Date.now();
    if (cacheKey in cache && cache[cacheKey].expiry > now) {
      return cache[cacheKey].data;
    }
    const apiName = process.env.NEXT_PUBLIC_DASH_API_NAME!;
    const path = 'realtime-daily-activity';
    const response = await API.get(apiName, path, {
      queryStringParameters: {
        startDate,
        endDate,
        students,
        alphaLevel,
      },
      multiValueQueryStringParameters: {
        students,
        alphaLevel,
      },
    });
    if (response?.result?.data?.json) {
      const data = response.result.data.json as RealtimeDailyActivityItem[];
      cache[cacheKey] = { expiry: now + cacheExpiry, data };
      return data;
    }
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

function invalidateExpiredCache() {
  const now = Date.now();
  for (const key in cache) {
    if (cache[key].expiry < now) {
      delete cache[key];
    }
  }
}

export function clearRealtimeApiCache() {
  cache = {};
}
