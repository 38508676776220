import { API } from '@aws-amplify/api';

export interface AppSubjectGradeThreshold {
  id: number;
  campus_id: number;
  app: string;
  subject: string;
  grade: number | null;
  target_mastery_units: number;
  business_key: string;
}

export async function getMasteryThresholds({
  studentIds,
  isMinThreshold,
}: {
  studentIds: string[],
  isMinThreshold?: boolean,
}): Promise<AppSubjectGradeThreshold[]> {
  try {
    const apiName = process.env.NEXT_PUBLIC_DASH_API_NAME!;
    const path = 'mastery-thresholds';
    const response = await API.get(apiName, path, {
      queryStringParameters: {
        studentIds,
        isMinThreshold,
      },
      multiValueQueryStringParameters: {
        studentIds,
      },
    });

    if (response?.result?.data?.json) {
      const data = response.result.data.json as AppSubjectGradeThreshold[];
      return data;
    }
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
