import { AuthUser, RawUserCustomData, Role } from '../@types/auth';
import getRole from './getRole';
import getLevels from './getLevel';
import * as Sentry from '@sentry/nextjs';

const getStudentId = (rawStudentIdString: string, role: Role): string => {
  if (role === Role.STUDENT) {
    return rawStudentIdString;
  }
  return rawStudentIdString;
};

const getChildStudentIds = (rawStudentIdString: string, role: Role): string[] => {
  if (role === Role.PARENT) {
    return rawStudentIdString.split(',');
  }
  return [];
};

type Data = {
  models: string[];
  permissions: string[];
  accessFilters: string[];
  groupIds: number[];
  userAttributes: any;
};

export interface CognitoUser {
  username: string;
  attributes?: {
    // question mark because they won't exist correctly on Google users, where we get them from...
    email: string;
    'custom:data': string;
    'custom:customName': string;
    'custom:customImage': string;
  };
}

export interface IDTokenPayload {
  email?: string; //... here.
  'custom:data'?: string;
  'custom:customName'?: string;
  'custom:customImage'?: string;
  'custom:error'?: string;
}

export const userAttributeAdapter = async (
  inputUser: CognitoUser,
  idTokenPayload: IDTokenPayload = {}
): Promise<AuthUser> => {
  if (!inputUser.attributes || !inputUser.attributes['custom:data']) {
    if (!('custom:data' in idTokenPayload)) {
      const error = new Error(
        'User without attributes loaded, but no idTokenPayload with attributes provided. Something went wrong with Google SSO.'
      );

      Sentry.withScope((scope) => {
        scope.setExtras({
          inputUser,
          idTokenPayload,
          customError: idTokenPayload['custom:error'],
        });
        scope.setUser({
          email: idTokenPayload.email,
        });
        Sentry.captureException(error);
      });

      throw error;
    }

    // Google users have their attributes copied from their regular user in the pre-token Lambda
    inputUser.attributes = {
      email: idTokenPayload.email as string,
      'custom:data': idTokenPayload['custom:data'] as string,
      'custom:customName': idTokenPayload['custom:customName'] as string,
      'custom:customImage': idTokenPayload['custom:customImage'] as string,
    };
  }

  const data = JSON.parse(inputUser.attributes['custom:data'] as string) as Data;
  const groupIds: number[] = data?.groupIds || [];
  const role = getRole(String(groupIds[0]));
  const rawStudentIdString = data?.userAttributes?.student_id;
  const studentId = getStudentId(rawStudentIdString, role);
  const studentGroup = data?.userAttributes?.student_group || '';
  const childStudentIds = getChildStudentIds(rawStudentIdString, role);
  const levels = await getLevels(role, groupIds, childStudentIds);
  const campusId = data?.userAttributes?.campus_id;
  const featureFlags = data?.userAttributes?.feature_flags;
  const authUser = inputUser as any as AuthUser;
  const rawCustomData = data as any as RawUserCustomData;
  const alphaAuthAttributes = {
    role: role,
    levels: levels,
    campusId: campusId,
    studentId: studentId,
    studentGroup: studentGroup,
    childStudentIds: childStudentIds,
    featureFlags: featureFlags,
  };
  if (authUser) {
    authUser.rawCustomData = rawCustomData;
    authUser.alphaAuthAttributes = alphaAuthAttributes;
  }

  return authUser;
};
