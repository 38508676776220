import { API } from 'aws-amplify';

export async function putTwoHourOnboardingStatus(input: {
  student_id: string;
}): Promise<{ affectedRows: number }> {
  try {
    const apiName = process.env.NEXT_PUBLIC_DASH_API_NAME!;
    const path = `api/onboarding/twohour/status`;
    const myInit = {
      headers: {},
      body: input,
    };
    return await API.put(apiName, path, myInit);
  } catch (error) {
    console.error('Error fetching auto roster status:', error);
    throw error;
  }
}
