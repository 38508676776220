import { API } from 'aws-amplify'; // AI-GEN - Cursor - Claude3 - Opus

export async function getMapStatus(input: {
  // AI-GEN - Cursor - Claude3 - Opus
  student_id: string; // AI-GEN - Cursor - Claude3 - Opus
}): Promise<any> {
  // AI-GEN - Cursor - Claude3 - Opus
  if (!input.student_id)
    // AI-GEN - Cursor - Claude3 - Opus
    return {
      // AI-GEN - Cursor - Claude3 - Opus
      student_id: input.student_id, // AI-GEN - Cursor - Claude3 - Opus
      map_status: null, // AI-GEN - Cursor - Claude3 - Opus
    }; // AI-GEN - Cursor - Claude3 - Opus
  try {
    // AI-GEN - Cursor - Claude3 - Opus
    const apiName = process.env.NEXT_PUBLIC_DASH_API_NAME!; // AI-GEN - Cursor - Claude3 - Opus
    const path = `api/testing/map/status`; // AI-GEN - Cursor - Claude3 - Opus
    const myInit = {
      // AI-GEN - Cursor - Claude3 - Opus
      headers: {}, // AI-GEN - Cursor - Claude3 - Opus
      queryStringParameters: {
        // AI-GEN - Cursor - Claude3 - Opus
        student_id: input.student_id, // AI-GEN - Cursor - Claude3 - Opus
      }, // AI-GEN - Cursor - Claude3 - Opus
    }; // AI-GEN - Cursor - Claude3 - Opus
    return await API.get(apiName, path, myInit); // AI-GEN - Cursor - Claude3 - Opus
  } catch (error) {
    // AI-GEN - Cursor - Claude3 - Opus
    console.error('Error fetching map status:', error); // AI-GEN - Cursor - Claude3 - Opus
    throw error; // AI-GEN - Cursor - Claude3 - Opus
  } // AI-GEN - Cursor - Claude3 - Opus
} // AI-GEN - Cursor - Claude3 - Opus
