import ThemeContrast from './ThemeContrast';
import ThemeRtlLayout from './ThemeRtlLayout';
// import ThemeColorPresets from './ThemeColorPresets';
import ThemeLocalization from './ThemeLocalization';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function ThemeSettings({ children }: Props) {
  // ThemeColorPresets is disabled for now because it overrides
  // color settings. This was from the old contraactor's original code,
  // and allowed users to choose what theme they wanted to render;
  // this is not functionality we want now, but it's been retained
  // to re-enable if desired.
  return (
    // <ThemeColorPresets>
    <ThemeContrast>
      <ThemeLocalization>
        <ThemeRtlLayout>{children}</ThemeRtlLayout>
      </ThemeLocalization>
    </ThemeContrast>
    // </ThemeColorPresets>
  );
}
