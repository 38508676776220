import { API } from 'aws-amplify';
import { GetNextBestTestDataInput } from './getNextBestTestData';
export interface Student {
  id: number;
  full_student_id: string;
  campus_id: number;
  student_id: number;
  first_name: string;
  preferred_name: string;
  last_name: string;
  date_of_birth: string;
  gender: string;
  current_level: string;
  current_grade_level: string;
  student_alpha_email: string;
  campus: string;
  student_group: string;
  withdraw_date: null | string;
  student_portfolio_url: string;
  full_name: string;
  level: string;
  level_value: number;
  advisor: null | string;
  guide: string;
  guide_email: string;
  learn_to_earn: number;
  universal_student_id: null | string;
}

export async function getStudentsByCampus(input: GetNextBestTestDataInput): Promise<Student[]> {
  const apiName = process.env.NEXT_PUBLIC_ACADEMICS_API_NAME!;
  const path = `api/testing/get-students-by-campus`;
  const myInit = {
    headers: {},
    queryStringParameters: {
      campus: input.campus,
    },
  };
  return await API.get(apiName, path, myInit);
}
