import { m } from 'framer-motion';
// @mui
import { alpha, styled, useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
//
import Logo from './Logo';
import useLayoutContext from '../hooks/useLayoutContext';
import useSiteConfig from 'common/hooks/useSiteConfig';

// ----------------------------------------------------------------------

const RootStyleFixed = styled('div')(({ theme }) => ({
  right: 0,
  bottom: 0,
  zIndex: 99999,
  width: '100%',
  height: '100%',
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
}));

const RootStyle = styled('div')(({ theme }) => ({
  right: 0,
  bottom: 0,
  zIndex: 1,
  width: '100%',
  height: '25vh',
  minHeight: '300px',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

function LoadingScreenInternal() {
  const theme = useTheme();
  const { logoHeight } = useLayoutContext();

  // Used as a semaphore to determine if the theme colors have been
  // loaded and whether or not we should render the primary ring as grey
  const { data } = useSiteConfig();

  const opacity = data ? theme.palette.loadingLogoOpacity : 1;
  const thinPulse = data ? theme.palette.cta.secondary : '#000000';
  const thickPulse = data ? theme.palette.cta.primary : '#000000';

  return (
    <>
      <m.div
        animate={{
          scale: [1, 0.9, 0.9, 1, 1],
          opacity: [1, 0.48, 0.48, 1, 1],
        }}
        transition={{
          duration: 2,
          ease: 'easeInOut',
          repeatDelay: 1,
          repeat: Infinity,
        }}
      >
        <Logo disabledLink sx={{ width: 64, height: logoHeight, opacity }} />
      </m.div>

      <Box
        component={m.div}
        animate={{
          scale: [1.2, 1, 1, 1.2, 1.2],
          rotate: [270, 0, 0, 270, 270],
          opacity: [0.25, 1, 1, 1, 0.25],
          borderRadius: ['25%', '25%', '50%', '50%', '25%'],
        }}
        transition={{ ease: 'linear', duration: 3.2, repeat: Infinity }}
        sx={{
          width: 100,
          height: 100,
          borderRadius: '25%',
          position: 'absolute',
          border: (theme) => `solid 3px ${alpha(thinPulse, 0.24)}`,
        }}
      />

      <Box
        component={m.div}
        animate={{
          scale: [1, 1.2, 1.2, 1, 1],
          rotate: [0, 270, 270, 0, 0],
          opacity: [1, 0.25, 0.25, 0.25, 1],
          borderRadius: ['25%', '25%', '50%', '50%', '25%'],
        }}
        transition={{
          ease: 'linear',
          duration: 3.2,
          repeat: Infinity,
        }}
        sx={{
          width: 120,
          height: 120,
          borderRadius: '25%',
          position: 'absolute',
          border: (theme) => `solid 8px ${alpha(thickPulse, 0.24)}`,
        }}
      />
    </>
  );
}

export default function LoadingScreen(props: any) {
  const { positionFixed = true, transparent = false } = props; // AI-GEN - Cursor - GPT4
  const backgroundStyle = transparent ? { backgroundColor: 'transparent' } : {}; // AI-GEN - Cursor - GPT4

  if (positionFixed)
    return (
      <RootStyleFixed style={backgroundStyle}>
        <LoadingScreenInternal />
      </RootStyleFixed>
    );
  else {
    return (
      <RootStyle style={backgroundStyle}>
        <LoadingScreenInternal />
      </RootStyle>
    );
  }
}
