import { API } from '@aws-amplify/api';

export async function deleteAppeal({ appealId }: { appealId: string }): Promise<void> {
  try {
    const apiName = process.env.NEXT_PUBLIC_DASH_API_NAME!;
    const path = `api/appeals/${appealId}`;
    const myInit = {};

    await API.del(apiName, path, myInit);
  } catch (error) {
    console.error(error);
    throw error;
  }
}
