import { Recommendation } from 'common/@types/recommendations';
import { createContext, ReactNode, useCallback, useEffect, useState } from 'react';

type toggleLessonInput = {
  recommendation?: Recommendation;
  type?: 'unblock' | 'support';
};

export type ModalUsageContextProps = {
  isLessonUnblockModalOpen: boolean;
  lessonUnblockData?: toggleLessonInput;
  toggleLessonUnblockModal: (data: toggleLessonInput) => void;
  showVideoModal: boolean;
  embedUrl: string;
  handleShowVideo: (url: string) => void;
  handleCloseVideo: () => void;
  isReportQsModalOpen: boolean;
  reportQsData?: {
    test_id?: string;
    test_name?: string;
    coach_email?: string;
    question_no?: string;
  };
  toggleReportQsModal: (data?: any) => void;
  reportedQuestions: {
    [key: string]: string[];
  };
  handleReportQuestionSession: (data: { question_no: string; test_name: string }) => void;
  skillUrl: string;
  setSkillUrl: (value: string) => void;
};

const initialState: ModalUsageContextProps = {
  isLessonUnblockModalOpen: false,
  lessonUnblockData: undefined,
  toggleLessonUnblockModal: () => {},
  showVideoModal: false,
  embedUrl: '',
  handleShowVideo: () => {},
  handleCloseVideo: () => {},
  isReportQsModalOpen: false,
  reportQsData: undefined,
  toggleReportQsModal: () => {},
  reportedQuestions: {},
  handleReportQuestionSession: () => {},
  skillUrl: '',
  setSkillUrl: () => {},
};

const ModalUsageContext = createContext<ModalUsageContextProps>(initialState);

type ModalUsageProviderProps = {
  children: ReactNode;
};

function ModalUsageProvider({ children }: ModalUsageProviderProps) {
  const [isLessonUnblockModalOpen, setIsLessonUnblockModalOpen] = useState(false);
  const [lessonUnblockData, setLessonUnblockData] = useState<toggleLessonInput>();
  const toggleLessonUnblockModal = ({ recommendation, type }: toggleLessonInput) => {
    setLessonUnblockData({ type, recommendation });
    setIsLessonUnblockModalOpen(!!recommendation);
  };
  const [skillUrl, setSkillUrl] = useState('');
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [embedUrl, setEmbedUrl] = useState('');

  const handleShowVideo = (url: string) => {
    setShowVideoModal(true);
    setEmbedUrl(url);
  };
  const handleCloseVideo = () => {
    setShowVideoModal(false);
    setEmbedUrl('');
  };
  const [reportedQuestions, setReportedQuestions] = useState<{
    [key: string]: string[];
  }>({});
  const [isReportQsModalOpen, setIsReportQsModalOpen] = useState(false);
  const [reportQsData, setReportQsData] = useState<any | undefined>();
  const toggleReportQsModal = useCallback(
    (data?: any) => {
      if (!data) {
        setReportQsData({
          ...reportQsData,
          question_no: undefined,
        });
        setIsReportQsModalOpen(false);
      } else {
        setReportQsData({
          ...reportQsData,
          ...data,
        });
        if (data?.question_no) {
          setIsReportQsModalOpen(!!data);
        }
      }
    },
    [reportQsData]
  );

  useEffect(() => {
    const reportedQuestions = JSON.parse(sessionStorage.getItem('reported_questions') || '{}');
    setReportedQuestions(reportedQuestions);
  }, []);

  useEffect(() => {
    console.log("reportedQuestions",reportedQuestions)
    sessionStorage.setItem('reported_questions', JSON.stringify(reportedQuestions));
  }, [reportedQuestions]);

  const handleReportQuestionSession = useCallback(
    (data: { question_no: string; test_name: string }) => {
      if (reportedQuestions[data.test_name]) {
        const questions = reportedQuestions[data.test_name];
        questions.push(data.question_no);
        setReportedQuestions({ ...reportedQuestions, [data.test_name]: questions });
      } else {
        setReportedQuestions({ ...reportedQuestions, [data.test_name]: [data.question_no] });
      }
    },
    [reportedQuestions]
  );

  return (
    <ModalUsageContext.Provider
      value={{
        isLessonUnblockModalOpen,
        lessonUnblockData,
        toggleLessonUnblockModal,
        showVideoModal,
        embedUrl,
        handleShowVideo,
        handleCloseVideo,
        isReportQsModalOpen,
        reportQsData,
        toggleReportQsModal,
        reportedQuestions,
        handleReportQuestionSession,
        skillUrl,
        setSkillUrl,
      }}
    >
      {children}
    </ModalUsageContext.Provider>
  );
}

export { ModalUsageContext, ModalUsageProvider };