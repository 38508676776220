import Script from 'next/script';

export default function GoogleAnalytics() {
  return (
    <div>
      <Script
        src="https://www.googletagmanager.com/gtag/js?id=G-TDZYHE3J34"
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){window.dataLayer.push(arguments);}
                  gtag('js', new Date());

                  gtag('config', 'G-TDZYHE3J34');
                `}
      </Script>
    </div>
  );
}
