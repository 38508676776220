import { API } from 'aws-amplify'; // AI-GEN - Cursor - GPT4

export interface StudentApp { // AI-GEN - ChatGPT - GPT4
  subject: string // AI-GEN - ChatGPT - GPT4
  app: string // AI-GEN - ChatGPT - GPT4
} // AI-GEN - ChatGPT - GPT4

export interface StudentAppSubjects { // AI-GEN - ChatGPT - GPT4
  studentId: string // AI-GEN - ChatGPT - GPT4
  subjects: StudentApp[] // AI-GEN - ChatGPT - GPT4
} // AI-GEN - ChatGPT - GPT4

export async function getActiveSubjectsByMultipleStudentIds(
  studentIds: string[]
): Promise<StudentAppSubjects[] | []> {
  // AI-GEN - Cursor - GPT4
  try {
    // AI-GEN - Cursor - GPT4
    const apiName = process.env.NEXT_PUBLIC_DASH_API_NAME!; // AI-GEN - Cursor - GPT4
    const path = `api/goals/get-active-subjects-by-multiple-student-ids`;
    const myInit = {
      // AI-GEN - Cursor - GPT4
      headers: {}, // AI-GEN - Cursor - GPT4
      queryStringParameters: { student_ids: studentIds.map(id => encodeURIComponent(id)).join(',') },
    }; // AI-GEN - Cursor - GPT4
    return await API.get(apiName, path, myInit); // AI-GEN - Cursor - GPT4
  } catch (error) {
    // AI-GEN - Cursor - GPT4
    console.error('Error fetching active subjects by studentId:', error); // AI-GEN - Cursor - GPT4
    throw error; // AI-GEN - Cursor - GPT4
  } // AI-GEN - Cursor - GPT4
} // AI-GEN - Cursor - GPT4
