import { API } from 'aws-amplify'; // AI-GEN - Cursor - GPT4
import { Goals } from './getGoalDetails';

export interface GoalsOutput {
  goals: Goals;
}

export async function getGoals(): Promise<GoalsOutput> {
  // AI-GEN - Cursor - GPT4
  try {
    // AI-GEN - Cursor - GPT4
    const apiName = process.env.NEXT_PUBLIC_DASH_API_NAME!; // AI-GEN - Cursor - GPT4
    const path = `api/goals/get-goals`; // AI-GEN - Cursor - GPT4
    const myInit = {
      // AI-GEN - Cursor - GPT4
      headers: {}, // AI-GEN - Cursor - GPT4
      queryStringParameters: {}, // AI-GEN - Cursor - GPT4
    }; // AI-GEN - Cursor - GPT4
    return await API.get(apiName, path, myInit); // AI-GEN - Cursor - GPT4
  } catch (error) {
    // AI-GEN - Cursor - GPT4
    console.error('Error fetching goals:', error); // AI-GEN - Cursor - GPT4
    throw error; // AI-GEN - Cursor - GPT4
  } // AI-GEN - Cursor - GPT4
} // AI-GEN - Cursor - GPT4
