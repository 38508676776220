import { SiteConfig } from 'common/@types/siteConfig';
import { trpc } from 'common/utils/trpcAmplify';
import { FetchStatus } from 'common/utils/constant';

type SiteConfigHookReturn = {
  data: SiteConfig | null | undefined;
  isPageBlacklisted: (path: string | undefined) => boolean;
  isLoading: boolean;
  fetchStatus: FetchStatus;
  initialLoadComplete: boolean;
};

export default function useSiteConfig(): SiteConfigHookReturn {
  const response = trpc.siteConfig.get.useQuery();

  function isPageBlacklisted(path: string | undefined): boolean {
    // If we don't want to consider the blacklisting, assume everything is cleared
    if (!process.env.USE_SITE_CONFIG_BLACKLISTING) {
      return false;
    }

    // Wait for the siteConfig to load
    if (!response.data) {
      return false;
    }

    // If no page was provided, assume it's whitelisted, return `false` (meaning user has permission)
    if (!path) {
      return false;
    }

    if (!response.data?.whitelistedPages) {
      console.error(
        `Developer error: siteConfig.whitelistedPages does not exist, thus everything is blacklisted.`
      );
      return true;
    }

    // Make sure the path starts with something in `siteConfig.whitelistedPages`
    const isWhitelisted = response.data.whitelistedPages.some((whitelistedPage) =>
      path.startsWith(whitelistedPage)
    );

    // If whitelisted, return `false` (meaning user has permission)
    return !isWhitelisted;
  }

  return {
    isPageBlacklisted,
    ...response,
  };
}
