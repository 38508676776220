import { API } from '@aws-amplify/api';
import { CoachingSummary } from 'common/@types/coaching';

export interface GetCoachingSummaryInput {
  studentIds: string[];
  startDate?: string;
  endDate?: string;
  mode: 'daily' | 'weekly';
}

export async function getCoachingSummary(
  input: GetCoachingSummaryInput
): Promise<CoachingSummary[]> {
  try {
    const apiName = process.env.NEXT_PUBLIC_DASH_API_NAME!;
    const path = 'api/summarized-coaching';
    // Use post to overcome the limit on student IDs when using query string parameters
    const response = await API.post(apiName, path, {
      queryStringParameters: {
        start_date: input.startDate,
        end_date: input.endDate,
        mode: input.mode,
      },
      body: {
        student_ids: input.studentIds,
      },
    });
    return response.result as CoachingSummary[];
  } catch (error) {
    console.error(error);
    throw error;
  }
}
