import { API } from 'aws-amplify'; // AI-GEN - Cursor - GPT4

export interface Goal {
  id: string;
  title: string;
}

export interface Goals {
  goals: Goal[];
}

export interface GoalDetailsOutput {
  // AI-GEN - Cursor - GPT4
  skillsLeft: number; // AI-GEN - Cursor - GPT4
  goalInfo: Goal; // AI-GEN - Cursor - GPT4
} // AI-GEN - Cursor - GPT4

export async function getGoalDetails(
  studentId: string,
  subject: string,
  goalId: string
): Promise<GoalDetailsOutput> {
  // AI-GEN - Cursor - GPT4
  try {
    // AI-GEN - Cursor - GPT4
    const apiName = process.env.NEXT_PUBLIC_DASH_API_NAME!; // AI-GEN - Cursor - GPT4
    const path = `api/goals/get-goal-details`; // AI-GEN - Cursor - GPT4
    const myInit = {
      // AI-GEN - Cursor - GPT4
      headers: {}, // AI-GEN - Cursor - GPT4
      queryStringParameters: {
        // AI-GEN - Cursor - GPT4
        student_id: studentId, // AI-GEN - Cursor - GPT4
        goal_id: goalId, // AI-GEN - Cursor - GPT4
        subject: subject, // AI-GEN - Cursor - GPT4
      }, // AI-GEN - Cursor - GPT4
    }; // AI-GEN - Cursor - GPT4
    return await API.get(apiName, path, myInit); // AI-GEN - Cursor - GPT4
  } catch (error) {
    // AI-GEN - Cursor - GPT4
    console.error('Error fetching goal details:', error); // AI-GEN - Cursor - GPT4
    throw error; // AI-GEN - Cursor - GPT4
  } // AI-GEN - Cursor - GPT4
} // AI-GEN - Cursor - GPT4
