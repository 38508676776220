import { API } from '@aws-amplify/api';
import { AppealItem } from '../../@types/appeals';

export async function getAppeals(): Promise<AppealItem[]> {
  try {
    const apiName = process.env.NEXT_PUBLIC_DASH_API_NAME!;
    const path = 'api/appeals';
    const myInit = {
      queryStringParameters: {},
    };
    const response = await API.get(apiName, path, myInit);
    return response.result.data.json;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
