import { API } from 'aws-amplify';
import type { Recommendation } from 'common/@types/recommendations';

export async function voluntaryTestOut(input: {
  studentId: string;
  subject: string;
}): Promise<Recommendation> {
  try {
    const apiName = process.env.NEXT_PUBLIC_DASH_API_NAME!;
    const path = `api/testing/voluntary-test-out`;
    const myInit = {
      headers: {},
      body: input,
    };
    const response = await API.post(apiName, path, myInit);
    return response.result.data.json;
  } catch (error) {
    console.error('Error processing voluntary test out:', error);
    throw error;
  }
}
