import { ReactNode, useEffect, useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
// hooks
import useSettings from '@common/hooks/useSettings';
import useResponsive from '@common/hooks/useResponsive';
import useCollapseDrawer from '@common/hooks/useCollapseDrawer';
// config
import { HEADER, NAVBAR } from '@common/config';
//
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';
import NavbarHorizontal from './navbar/NavbarHorizontal';
import { TOKEN_EXPIRED_EVENT } from '@common/utils/constant';
import useAuth from '@common/hooks/useAuth';
import { useSnackbar } from 'notistack';
import { CurrentViewType } from 'common/analytics/amplitude';

// ----------------------------------------------------------------------

type MainStyleProps = {
  collapseClick: boolean;
};

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})<MainStyleProps>(({ collapseClick, theme, style }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
  ...style,
}));

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
  currentView?: CurrentViewType;
  headerBgColor?: string;
  mainStyle?: any;
};

export default function DashboardLayout({
  children,
  currentView,
  headerBgColor,
  mainStyle,
}: Props) {
  const { collapseClick, isCollapse } = useCollapseDrawer();

  const { themeLayout } = useSettings();

  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);

  const { logout } = useAuth();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const handleTokenExpired = () => {
      enqueueSnackbar('Your session ended, please log in again.', { variant: 'warning' });
      logout().catch((e) => {
        enqueueSnackbar('Unable to logout!', { variant: 'error' });
        console.error(e);
      });
    };

    window.addEventListener(TOKEN_EXPIRED_EVENT, handleTokenExpired);

    return () => {
      window.removeEventListener(TOKEN_EXPIRED_EVENT, handleTokenExpired);
    };
  }, [logout]);

  const verticalLayout = themeLayout === 'vertical';

  if (verticalLayout) {
    return (
      <>
        <DashboardHeader
          onOpenSidebar={() => setOpen(true)}
          verticalLayout={verticalLayout}
          currentView={currentView}
          bgColor={headerBgColor}
        />

        {isDesktop ? (
          <NavbarHorizontal />
        ) : (
          <NavbarVertical
            isOpenSidebar={open}
            onCloseSidebar={() => setOpen(false)}
            currentView={currentView}
          />
        )}

        <Box
          component="main"
          sx={{
            px: { lg: 2 },
            pt: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
            },
            pb: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
            },
          }}
        >
          {children}
        </Box>
      </>
    );
  }

  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
      }}
    >
      <DashboardHeader
        isCollapse={isCollapse}
        onOpenSidebar={() => setOpen(true)}
        currentView={currentView}
        bgColor={headerBgColor}
      />

      <NavbarVertical
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
        currentView={currentView}
      />

      <MainStyle style={mainStyle} collapseClick={collapseClick}>
        {children}
      </MainStyle>
    </Box>
  );
}
