import { API } from '@aws-amplify/api';
import { XAppInfo } from '@common/@types/apps';

export async function getApps(data: { studentId: string; level: string }): Promise<XAppInfo[]> {
  try {
    if (!data.studentId) return [];

    const apiName = process.env.NEXT_PUBLIC_DASH_API_NAME!;
    const path = 'get-apps';
    const myInit = {
      headers: {},
      body: {
        data,
      },
    };
    const response = await API.post(apiName, path, myInit);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
