import { pollAmplifyAPI } from './pollAmplifyAPI';

export interface OverrideRecommendation {
  student_id: string;
  type: string;
  name: string;
  url: string;
  grade: string;
  subject_id: number;
  suggested_content: string;
  smart_score: string;
  status: string;
  priority: string;
  block: string;
  source_id: number;
  skill_id: string;
  recommendation_source: string;
}

export interface RecommendationsOverrideRequest {
  student_id: string;
  recommendations: OverrideRecommendation[];
}

type Recommendation = {
  student_id: string;
  grade: string;
  priority: number;
  source_id: number;
  subject_id: number;
  recommendation_source: string;
  name: string;
  apiStatus: 'created' | 'updated' | 'error';
  uuid: string;
};

type Summary = {
  created: number;
  updated: number;
  failed: number;
  invalidated: number;
};

export interface RecommendationsOverrideResult {
  recommendations: Recommendation[];
  summary: Summary;
}

export async function createRecommendationOverrides(
  input: RecommendationsOverrideRequest
): Promise<RecommendationsOverrideResult> {
  try {
    const apiName = process.env.NEXT_PUBLIC_ACADEMICS_API_NAME!;
    const path = `api/recommendations/override?studentId=${input.student_id}`;
    const requestParameters = {
      queryStringParameters: {},
      body: input.recommendations,
    };

    const response = await pollAmplifyAPI('post', apiName, path, requestParameters);
    if (!response || !response.result) {
      return response;
    }
    return response.result;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
