import { useAppDispatch } from '@common/hooks/useAppDispatch';
import { useAppSelector } from '@common/hooks/useAppSelector';
import { useEffect } from 'react';
import {
  initializeDashboardFilterSelections,
  setSelectedStudents,
  setDate,
} from './slices/dashboardFilterSelections';
import useAuth from '@common/hooks/useAuth';
import { Role } from '../@types/auth';
import dayjs from '@common/@types/dayjs-custom';

const InitializeReduxStore = ({ children }: Props) => {
  const { user } = useAuth();
  const role = user?.alphaAuthAttributes?.role;
  const dispatch = useAppDispatch();

  // This feels like a bit of a hack to someone who's new-ish with Redux, but
  // it's working and React isn't complaining. We're pulling the date as pulled
  // from localStorage, then passing it back into the same module where we
  //  specify the initial state as blank. I _think_ this is correct, and it's working
  // as I wanted it to, but it still doesn't feel right.
  const { date } = useAppSelector((state) => state.dashboardFilterSelections.dateDetails);

  useEffect(() => {
    // Ensure that user is defined before initializing anything that requires
    // an API call
    if (!user) return;

    // Init default student lists
    if (role === Role.STUDENT) {
      dispatch(setSelectedStudents([user?.alphaAuthAttributes?.studentId]));
    } else if (role === Role.PARENT) {
      dispatch(setSelectedStudents(user?.alphaAuthAttributes?.childStudentIds));
    }

    // If we have a date that we're working with, make sure week/session/year are init
    if (date) {
      dispatch(initializeDashboardFilterSelections(date));
    }
    // Otherwise, default back to yesterday FOR THE USER so that the user has data to look at
    else {
      const defaultDate = dayjs().subtract(1, 'day');
      setDate(defaultDate.format('YYYY-MM-DD'));
    }
  }, [dispatch, user]);
  return <>{children}</>;
};

type Props = {
  children: React.ReactNode;
};

export default InitializeReduxStore;
