import { API } from '@aws-amplify/api';

interface DeleteUserInput {
  email: string;
}

export async function deleteUser(input: DeleteUserInput): Promise<void> {
  try {
    const apiName = process.env.NEXT_PUBLIC_DASH_API_NAME!;
    const path = 'delete-user';
    const myInit = {
      queryStringParameters: {},
      body: input,
    };

    const response = await API.del(apiName, path, myInit);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
