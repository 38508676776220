import { API } from '@aws-amplify/api';
import { PostAPInput } from '../../@types/antipattern';

export async function createAntipattern(input: PostAPInput): Promise<void> {
  try {
    const apiName = process.env.NEXT_PUBLIC_ACADEMICS_API_NAME!;
    const path = 'api/antipatterns';
    const myInit = {
      queryStringParameters: {},
      body: input,
    };

    await API.post(apiName, path, myInit);
  } catch (error) {
    console.error(error);
    throw error;
  }
}
