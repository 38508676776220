import { createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { AppDispatch, dispatch } from '../store';
import { getLatestYear, GetAllDaysOutput, getAllDays } from '@common/utils/amplify/getAllDays';
import dayjs from '@common/@types/dayjs-custom';

// ----------------------------------------------------------------------

const initialState = {
  showing2hr: true,
  selectedStudents: [] as string[],
  dateDetails: {
    schoolYear: '',
    session: '',
    week: '',
    date: '',
  },
};

const slice = createSlice({
  name: 'dashboardFilterSelections',
  initialState,
  reducers: {
    clearAll(state, action) {
      state.selectedStudents = initialState.selectedStudents;
      state.dateDetails = initialState.dateDetails;
      state.showing2hr = initialState.showing2hr;
    },

    setSelectedStudents(state, action) {
      state.selectedStudents = action.payload;
    },

    setDateDetails(state, action) {
      state.dateDetails.schoolYear = action.payload.schoolYear;
      state.dateDetails.session = action.payload.session;
      state.dateDetails.week = action.payload.week;
      state.dateDetails.date = action.payload.currentDate || state.dateDetails.date;
    },

    setWeek(state, action) {
      state.dateDetails.week = action.payload;
    },

    setDate(state, action) {
      state.dateDetails.date = action.payload;
    },

    setShowing2hr(state, action) {
      state.showing2hr = action.payload;
    },
  },
});

export default slice.reducer;
export const { clearAll, setSelectedStudents, setDateDetails, setWeek } = slice.actions;

export const getDateDetails = async (
  dateIn: string | undefined, // undefined for current date
  onSuccess: (response: GetAllDaysOutput, ymd: string) => void,
  onFailure: (error: AxiosError) => void
) => {
  const date = dateIn ? dayjs(dateIn) : dayjs();
  const ymd: string = date.format('YYYY-MM-DD');

  try {
    const dateCache = await getAllDays();
    onSuccess(dateCache.dayInfo[ymd], ymd);
  } catch (error) {
    onFailure(error);
  }
};

async function dispatchResponse(response: GetAllDaysOutput, ymd: string) {
  const dateDetails: any = {};
  if (response) {
    dateDetails.schoolYear = response.school_year;
    dateDetails.session = response.session;
    dateDetails.week = response.week_num;
  } else {
    console.warn(`No week/session/year info for ${ymd}.`);

    if (!dateDetails.session) {
      dateDetails.schoolYear = await getLatestYear();
      dateDetails.session = 1;
      dateDetails.week = 1;
    }
  }
  dispatch(slice.actions.setDateDetails({ ...dateDetails }));
}

async function dispatchFailure(error: AxiosError) {
  console.error(error);

  const defaultObj = { schoolYear: await getLatestYear(), session: 1, week: 1 };
  dispatch(slice.actions.setDateDetails(defaultObj));
}

export const setDate = async (date: string) => {
  dispatch(slice.actions.setDate(date));
  await getDateDetails(date, dispatchResponse, dispatchFailure);
};

const initializeDateDetails = (date: string | undefined) => async (dispatch: AppDispatch) =>
  getDateDetails(date, dispatchResponse, dispatchFailure);

export const initializeDashboardFilterSelections =
  (date: string | undefined) => async (dispatch: AppDispatch) => {
    dispatch(initializeDateDetails(date));

    // To satisfy `yarn build`
    if (typeof window === 'undefined') return;

    if (localStorage.getItem('default2hr') === null) {
      setShowing2hr(true);
      localStorage.setItem('default2hr', 'set');
    }
  };

export const setShowing2hr = async (view: boolean) => {
  dispatch(slice.actions.setShowing2hr(view));
};
