import { API } from '@aws-amplify/api';
import { InfractionItem } from '../../@types/infractions';

export async function getInfractions({
  studentId,
}: {
  studentId: string;
}): Promise<InfractionItem[]> {
  try {
    const apiName = process.env.NEXT_PUBLIC_DASH_API_NAME!;
    const path = 'api/infractions';
    const otpions = {
      queryStringParameters: { studentId },
    };
    const response = await API.get(apiName, path, otpions);
    return response.result.data.json;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
