import { pollAmplifyAPI } from './pollAmplifyAPI';

export interface CreateStudentRosterInput {
  studentId: string;
  subject: string;
  app: string;
  level: string;
  reasonForPlacement: string;
  createdBy: string;
  invalidate: boolean;
}

export interface CreateStudentRosterOutput {
  success?: string;
  error?: string;
}

export async function createStudentRosterEntry(
  input: CreateStudentRosterInput
): Promise<CreateStudentRosterOutput> {
  try {
    const apiName = process.env.NEXT_PUBLIC_ACADEMICS_API_NAME!;
    const path = 'api/management/create-student-roster-entry';
    const requestParameters = {
      queryStringParameters: {},
      body: input,
    };

    const response = await pollAmplifyAPI('post', apiName, path, requestParameters);
    if (!response || !response.result) {
      return response;
    }
    return response.result;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
