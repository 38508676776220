import { API } from '@aws-amplify/api';

export type Subject = {
  id: number;
  subject: string;
};

export async function getTestingSubjects(): Promise<Subject[]> {
  try {
    const apiName = process.env.NEXT_PUBLIC_ACADEMICS_API_NAME!;
    const path = 'api/testing/subject';
    const response = await API.get(apiName, path, {});
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
