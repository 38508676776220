import { useEffect, useState } from 'react';
import { throttle } from 'lodash';
// form
import { Controller, useFormContext } from 'react-hook-form';
// @mui
import { SxProps, TextField, TextFieldProps, useTheme } from '@mui/material';

// ----------------------------------------------------------------------

type IProps = {
  name: string;
  maxLength?: number;
};

type Props = IProps & TextFieldProps;

export default function RHFTextField({ name, maxLength, ...other }: Props) {
  const { watch, getValues, control } = useFormContext();
  const theme = useTheme();

  // Based off https://stackoverflow.com/a/54666498
  // This shit's magic. We start with the length of the field:
  const [currentLength, setCurrentLength] = useState(getValues(name)?.length ?? 0);
  // Then, lodash.throttle takes a function, which in this case...
  const throttled = throttle(
    // ...updates the current value of the ref...
    (len: number) => setCurrentLength(len),
    // ...at most every 500ms...
    500,
    // ...and ensures it gets called once at the end.
    { trailing: true }
  );

  // Then, we set up a `watch(field)` listener, which...
  useEffect(() => {
    // ...runs the throttled function...
    throttled(
      // ...and passes the current length in to update the ref.
      getValues(name)?.length || 0
    );
  }, [watch(name)]);

  if (maxLength) {
    if (!other.inputProps) {
      other.inputProps = {};
    }

    other.inputProps.maxLength = maxLength;
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        let fieldError = !!error;
        let helperText = error?.message;
        let helperTextStyle: SxProps = {};
        if (!helperText && maxLength) {
          helperText = `${currentLength}/${maxLength}`;
          helperTextStyle = { marginTop: '3px', marginLeft: '6px' };
          if (currentLength / maxLength > 1) {
            fieldError = true;
            helperTextStyle.color = theme.palette.error.main;
          }
        }
        const sx = {
          ...other.sx,
          '.MuiFormHelperText-root': helperTextStyle,
        };
        return (
          <TextField
            {...field}
            fullWidth
            error={fieldError}
            helperText={helperText}
            {...other}
            sx={sx}
          />
        );
      }}
    />
  );
}
