import { ReactNode } from 'react';
import useLayoutContext from '@common/hooks/useLayoutContext';
// components
import DashboardLayout from './dashboard';
import LogoOnlyLayout from './LogoOnlyLayout';
import '@common/utils/amplify/configureAmplifyApi';
import { CurrentViewType } from 'common/analytics/amplitude';

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
  variant?: 'dashboard' | 'logoOnly';
  pageName?: CurrentViewType;
  headerBgColor?: string;
  mainStyle?: any;
};

export default function Layout({
  variant = 'dashboard',
  pageName,
  children,
  mainStyle,
  headerBgColor,
}: Props) {
  const { getTypedGuard } = useLayoutContext();

  const AuthGuard = getTypedGuard('AuthGuard');
  const MobileGuard = getTypedGuard('MobileGuard');
  const PageGuard = getTypedGuard('PageGuard');

  if (variant === 'logoOnly') {
    return <LogoOnlyLayout> {children} </LogoOnlyLayout>;
  }

  return (
    <AuthGuard>
      <MobileGuard>
        <PageGuard>
          <DashboardLayout
            currentView={pageName}
            headerBgColor={headerBgColor}
            mainStyle={mainStyle}
          >
            {children}
          </DashboardLayout>
        </PageGuard>
      </MobileGuard>
    </AuthGuard>
  );
}
