import { CombinedSkill } from '@common/@types/recommendations';
import { API } from 'aws-amplify';
let cachedCombinedSkills: CombinedSkill[] | null = null;
let cacheTimestamp: number | null = null;
const oneHour = 1 * 60 * 60 * 1000; // One hour in milliseconds

export async function getAllCombinedSkills(): Promise<CombinedSkill[]> {
  if (cachedCombinedSkills && cacheTimestamp && (Date.now() - cacheTimestamp < oneHour)) {
    return cachedCombinedSkills;
  }
  const apiName = process.env.NEXT_PUBLIC_ACADEMICS_API_NAME!;
  const path = `api/combined-skills`;
  const limit = 10000;
  let allResults: CombinedSkill[] = [];
  let offset = 0;

  try {
    while (true) {
      const myInit = {
        headers: {},
        queryStringParameters: {
          limit: limit.toString(),
          offset: offset.toString(),
        },
      };
      const response: { results: CombinedSkill[] } = await API.get(apiName, path, myInit);
      allResults = allResults.concat(response.results);
      if (response.results.length < limit) {
        break;
      }
      offset += limit;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }

  cachedCombinedSkills = allResults;
  cacheTimestamp = Date.now();
  return allResults;
}
