import { API } from '@aws-amplify/api';
import { Antipattern } from '../../@types/antipattern';

export async function putAntipattern(input: Antipattern): Promise<void> {
  try {
    const apiName = process.env.NEXT_PUBLIC_ACADEMICS_API_NAME!;
    const path = `api/antipatterns`;
    const myInit = {
      queryStringParameters: {},
      body: input,
    };

    await API.put(apiName, path, myInit);
  } catch (error) {
    console.error(error);
    throw error;
  }
}
