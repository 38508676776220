// next
import { NextApiRequestCookies } from 'next/dist/server/api-utils';
// config
import { cookiesKey, defaultSettings } from '../config';

// ----------------------------------------------------------------------

export const getSettings = (cookies: NextApiRequestCookies) => {
  const themeMode = getData(cookies[cookiesKey.themeMode] as string) || defaultSettings.themeMode;

  const themeDirection =
    getData(cookies[cookiesKey.themeDirection] as string) || defaultSettings.themeDirection;

  const themeColorPresets =
    getData(cookies[cookiesKey.themeColorPresets] as string) || defaultSettings.themeColorPresets;

  const themeLayout =
    getData(cookies[cookiesKey.themeLayout] as string) || defaultSettings.themeLayout;

  const themeContrast =
    getData(cookies[cookiesKey.themeContrast] as string) || defaultSettings.themeContrast;

  const themeStretch =
    getData(cookies[cookiesKey.themeStretch] as string) || defaultSettings.themeStretch;

  return {
    themeMode,
    themeLayout,
    themeStretch,
    themeContrast,
    themeDirection,
    themeColorPresets,
  };
};

// ----------------------------------------------------------------------

const getData = (value: string) => {
  if (value === 'true' || value === 'false') {
    return JSON.parse(value);
  }
  if (value === 'undefined' || !value) {
    return '';
  }
  return value;
};
