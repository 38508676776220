import { API } from 'aws-amplify'; // AI-GEN - Cursor - GPT4

export interface StudentSubjects {
  // AI-GEN - ChatGPT - GPT4
  studentId: string; // AI-GEN - ChatGPT - GPT4
  subjects: string[]; // AI-GEN - ChatGPT - GPT4
} // AI-GEN - ChatGPT - GPT4

export async function getActiveSubjectsByStudentId(
  studentId: string
): Promise<StudentSubjects | []> {
  // AI-GEN - Cursor - GPT4
  try {
    // AI-GEN - Cursor - GPT4
    const apiName = process.env.NEXT_PUBLIC_DASH_API_NAME!; // AI-GEN - Cursor - GPT4
    const path = `api/goals/get-active-subjects-by-studentid`; // AI-GEN - Cursor - GPT4
    const myInit = {
      // AI-GEN - Cursor - GPT4
      headers: {}, // AI-GEN - Cursor - GPT4
      queryStringParameters: { student_id: studentId }, // AI-GEN - Cursor - GPT4
    }; // AI-GEN - Cursor - GPT4
    return await API.get(apiName, path, myInit); // AI-GEN - Cursor - GPT4
  } catch (error) {
    // AI-GEN - Cursor - GPT4
    console.error('Error fetching active subjects by studentId:', error); // AI-GEN - Cursor - GPT4
    throw error; // AI-GEN - Cursor - GPT4
  } // AI-GEN - Cursor - GPT4
} // AI-GEN - Cursor - GPT4
