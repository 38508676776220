// Import useAppSelector
import { useAppSelector } from '@common/hooks/useAppSelector';
import { useRouter } from 'next/router';
import { mixpanelService } from '../analytics/mixpanel';
import { useEffect } from 'react';
import { amplitudeService } from 'common/analytics/amplitude';

// Create a new component that calls usePageViews
export function PageViewTracker() {
  const router = useRouter();
  const twoHrSession = useAppSelector((state) => state.dashboardFilterSelections.showing2hr);

  // Send a page view event on first load
  useEffect(() => {
    const sessionType = twoHrSession.toString() === 'false' ? '2x' : '2hr';
    mixpanelService.track('Page View', {
      page: window.location.pathname,
      sessionType: sessionType,
    });
    amplitudeService.trackPageViewed();
  }, []);

  // Send a page view event when the route changes
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      const sessionType = twoHrSession.toString() === 'false' ? '2x' : '2hr';
      mixpanelService.track('Page View', { page: url, sessionType: sessionType });
      amplitudeService.trackPageViewed();
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router, twoHrSession]);

  // Handle laod errors
  useEffect(() => {
    const handleRouteChangeError = (err: any, url: string) => {
      // The only way we should get to this point is if we had an unexpected 404,
      // like if a file got deleted from CloudFormation. In that case, we want to
      // force a refresh so that the user gets an updated copy of the build mainfest.
      if (err.message.startsWith('Error: Failed to load script:')) {
        window.location.reload();
        return;
      }
      console.error('Unexpected load failure:', err, url);
    };
    router.events.on('routeChangeError', handleRouteChangeError);
    return () => {
      router.events.off('routeChangeError', handleRouteChangeError);
    };
  }, [router, twoHrSession]);

  return null; // This component doesn't render anything
}
