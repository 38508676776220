import { API } from '@aws-amplify/api';
import { Chart } from '@common/@types/check_charts';

export async function getCharts(data: {
  level?: string;
  id?: string;
  campus_id?: string;
}): Promise<Chart[]> {
  try {
    const apiName = process.env.NEXT_PUBLIC_DASH_API_NAME!;
    const queryParams = [];
    if ('level' in data) queryParams.push(`level=${data.level}`);
    if ('id' in data) queryParams.push(`id=${data.id}`);
    if ('campus_id' in data) queryParams.push(`campus_id=${data.campus_id}`);
    if ('enabled' in data) queryParams.push(`enabled=${data.enabled}`);
    const path = `api/chart?${queryParams.join('&')}`;
    const myInit = {
      headers: {},
      query: data,
    };
    const response = await API.get(apiName, path, myInit);
    return response.result;
  } catch (error) {
    console.error(error);
    throw new Error('Make sure you have permission to view this chart.');
  }
}
