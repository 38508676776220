import { pollAmplifyAPI } from './pollAmplifyAPI';
import { GetSessionSnapshotOutput } from '@common/@types/snapshots';

export async function getSessionSnapshot(
  input: {
    studentId?: string;
    session?: string;
  },
  amplifyConfig?: { [key: string]: any }
): Promise<GetSessionSnapshotOutput> {
  const apiName = process.env.NEXT_PUBLIC_DASH_API_NAME;
  if (!apiName) {
    throw new Error('API name is not defined');
  }

  if (!amplifyConfig) amplifyConfig = {};
  amplifyConfig.queryStringParameters = input;

  const response = await pollAmplifyAPI('get', apiName, 'session-snapshot', amplifyConfig);

  return response;
}
