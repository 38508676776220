import { API } from 'aws-amplify';

/**
 * Uses the invoke-lambda/trigger-lambda/poll-lambda stack to run a Lambda
 * that we expect to go over the API Gateway timeout.
 *
 * To switch to this, change
 * - API.post(apiName, path, config)
 * to
 * - pollAmplifyAPI('post', apiName, path, config)
 *
 * After `maxRetries * pollInterval` milliseconds have passed, this will
 * throw an error message, which needs to be handled.
 *
 * @param method - the method of the call we want to make (API.get => 'get', API.post => 'get'), case-insensitive
 * @param apiName - the API of the call we want to make, from API.___ params
 * @param path - the path to make the request to, from API.___ params
 * @param config - the config to pass to the invoked lambda, `body` will be proxied through
 * @param pollInterval - optional, number (in milliseconds) to wait between poll requests; defaults to 1 second
 * @param maxRetries - optional, number of times to retry before giving up; defaults to pollInterval * 900 (15 mins)
 */
export async function pollAmplifyAPI(
  method: string,
  apiName: string,
  path: string,
  config: any,
  pollInterval = 1000,
  maxRetries = 60 * 15 // default to 15 mins
) {
  if (!config.queryStringParameters) config.queryStringParameters = {};
  config.queryStringParameters.triggerAsyncLambda = 'true';
  let invokeResponse;
  if (!method) throw new Error('No method provided');
  switch (method.toLowerCase()) {
    case 'get':
      invokeResponse = await API.get(apiName, path, config);
      break;
    case 'post':
      invokeResponse = await API.post(apiName, path, config);
      break;
    case 'put':
      invokeResponse = await API.put(apiName, path, config);
      break;
    case 'patch':
      invokeResponse = await API.patch(apiName, path, config);
      break;
    case 'delete':
      invokeResponse = await API.del(apiName, path, config);
      break;
    default:
      throw new Error(`Invalid method: ${method}`);
  }

  // If we didn't get a `requestId` param, something failed.
  // Would be REALLY nice if Amplify returned status codes.
  if (!invokeResponse.requestId) {
    return invokeResponse;
  }

  const { requestId } = invokeResponse;

  // Config to send to poll-lambda request
  const pollConfig = {
    body: { requestId },
  };

  // Always wait an initial 2 seconds before considering the pollInterval; this helps
  // avoid issues where we accidentally query before the semaphore S3 file was created
  await new Promise((resolve) => window.setTimeout(resolve, 2000));

  // On the backend, we use the max timeout for Lambda (15 minutes), and there's a
  // chance something could go horribly wrong and end up with us being in 202 purgatory
  // indefinitely. The default pollInterval/maxRetries config gives us 15 minutes and
  // then flips to the error message below; reconfigure as you wish.
  let retryCount = 0;
  do {
    let pollResponse;
    try {
      await new Promise((resolve) => window.setTimeout(resolve, pollInterval));
      const pollApiName = process.env.NEXT_PUBLIC_DASH_API_NAME!;
      pollResponse = await API.post(pollApiName, 'poll-lambda', pollConfig);
      if (pollResponse === 'INCOMPLETE') {
        console.log('Invoke not yet finished, waiting and trying again...');
        retryCount++; // Increment the retry counter
        if (retryCount >= maxRetries) {
          // The text here is presented to the user if it's encountered
          throw new Error('Your request took too long to complete. Please try again later.');
        }
      } else return pollResponse;
    } catch (error) {
      console.error('Lambda poll errored:', error);
      throw error;
    }
  } while (true);
}
