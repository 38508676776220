import { pollAmplifyAPI } from './pollAmplifyAPI'; // AI-GEN - Cursor - Claude3 - Opus

export interface MapAssignmentRequest {
  // AI-GEN - Cursor - Claude3 - Opus
  studentId: string; // AI-GEN - Cursor - Claude3 - Opus
  subject: string; // AI-GEN - Cursor - Claude3 - Opus
} // AI-GEN - Cursor - Claude3 - Opus

export interface MapAssignmentResult {
  // AI-GEN - Cursor - Claude3 - Opus
  student_id?: string; // AI-GEN - Cursor - Claude3 - Opus
  apiStatus: 'created' | 'updated' | 'error'; // AI-GEN - Cursor - Claude3 - Opus
  error?: string; // AI-GEN - Cursor - Claude3 - Opus
} // AI-GEN - Cursor - Claude3 - Opus

export async function assignMapTest( // AI-GEN - Cursor - Claude3 - Opus
  input: MapAssignmentRequest // AI-GEN - Cursor - Claude3 - Opus
): Promise<MapAssignmentResult> {
  // AI-GEN - Cursor - Claude3 - Opus
  try {
    // AI-GEN - Cursor - Claude3 - Opus
    const apiName = process.env.NEXT_PUBLIC_ACADEMICS_API_NAME!; // AI-GEN - Cursor - Claude3 - Opus
    const path = 'api/testing/assign-map'; // AI-GEN - Cursor - Claude3 - Opus
    const requestParameters = {
      // AI-GEN - Cursor - Claude3 - Opus
      queryStringParameters: {}, // AI-GEN - Cursor - Claude3 - Opus
      body: input, // AI-GEN - Cursor - Claude3 - Opus
    }; // AI-GEN - Cursor - Claude3 - Opus

    const response = await pollAmplifyAPI('post', apiName, path, requestParameters); // AI-GEN - Cursor - Claude3 - Opus
    if (!response || !response.result) {
      // AI-GEN - Cursor - Claude3 - Opus
      return response; // AI-GEN - Cursor - Claude3 - Opus
    } // AI-GEN - Cursor - Claude3 - Opus
    return response.result; // AI-GEN - Cursor - Claude3 - Opus
  } catch (error) {
    // AI-GEN - Cursor - Claude3 - Opus
    console.error(error); // AI-GEN - Cursor - Claude3 - Opus
    throw error; // AI-GEN - Cursor - Claude3 - Opus
  } // AI-GEN - Cursor - Claude3 - Opus
} // AI-GEN - Cursor - Claude3 - Opus
