import { API } from '@aws-amplify/api';

export interface GetAllWeeksItem {
  week_num: number;
  session: number;
  campusid: number;
}

export async function getAllWeeks(input: {
  schoolYear: string;
  maxDate: string;
}): Promise<GetAllWeeksItem[]> {
  try {
    const apiName = process.env.NEXT_PUBLIC_DASH_API_NAME!;
    const path = '/all-days/week';
    const myInit = {
      headers: {},
      queryStringParameters: input,
    };
    const response = await API.get(apiName, path, myInit);
    return response.result.data.json;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
