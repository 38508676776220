import { API } from '@aws-amplify/api';
import { TestCoachingForm } from 'common/@types/test-coaching';

export async function getPostCoachingListing(): Promise<TestCoachingForm[]> {
  try {
    const apiName = process.env.NEXT_PUBLIC_ACADEMICS_API_NAME!;
    const path = `api/coaching/test-coaching-form-listing`;
    const myInit = {};
    const response = await API.get(apiName, path, myInit);
    return response.result;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
