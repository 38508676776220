import * as React from 'react';
import Iconify from '../Iconify';
import { useTheme } from '@mui/material';
import useSiteConfig from 'common/hooks/useSiteConfig';

export default function HelpDeskIcon() {
  const theme = useTheme();
  const isHelpIconVisible = true;
  const { data } = useSiteConfig();
  const helpDeskUrl = data?.links?.helpdesk ?? 'https://alpha-school-support.zendesk.com/hc/en-us';
  if (isHelpIconVisible === true) {
    return (
      <a
        href={helpDeskUrl}
        target="_blank"
        title="How can we help?"
        rel="noopener noreferrer"
        style={{ position: 'fixed', right: '24px', bottom: '12px', zIndex: 1000 }}
      >
        <Iconify icon="pajamas:question" width={48} height={48} color={theme.palette.zendesk} />
      </a>
    );
  }
  return <span />;
}
