import { API } from '@aws-amplify/api';
import LZString from 'lz-string';
import { getLatestYearFull } from './getAllDays';
import dayjs from '@common/@types/dayjs-custom';

export type GetStudentsInput = {
  // AI-GEN - Cursor - GPT4
  schoolYear?: string; // AI-GEN - Cursor - GPT4
  alphaLevel?: number[]; // AI-GEN - Cursor - GPT4
  studentIds?: string[]; // AI-GEN - Cursor - GPT4
  campusIds?: string[]; // AI-GEN - Cursor - GPT4
}; // AI-GEN - Cursor - GPT4

export interface Student {
  alpha_level: string;
  id: string;
  name: string;
  student_id: string;
  alpha_level_short: string;
  student_group: string;
}

// How long an item in the cache should stay before being invalidated
const CACHE_TTL = 1000 * 60 * 5; // 5 minutes in ms

// LocalStorage key
const LOCAL_STORAGE_KEY = 'dash-student-cache';

type StudentList = Student[];

type StudentListCacheEntry = {
  expiry: number;
  students: StudentList;
};
interface StudentCache {
  studentList?: Map<string, StudentListCacheEntry>;
}
let cache: StudentCache | null = null;

export function clearStudentList() {
  cache = {};
  writeCache();
}

function initCache() {
  if (typeof window === 'undefined') {
    cache = {};
    return;
  }
  if (cache) return;

  const cacheRaw = localStorage?.getItem(LOCAL_STORAGE_KEY);

  // Safety: in case we still have JSON, just parse it
  if (cacheRaw?.[0] === '{') {
    try {
      cache = JSON.parse(cacheRaw, reviver);
    } catch (error) {
      console.error('Failed to parse getStudents cache from local storage:', error);
      cache = {};
      writeCache();
    }
  } else if (cacheRaw) {
    // Otherwise, assume it's LZString-encoded and decompress it
    try {
      cache = JSON.parse(LZString.decompress(cacheRaw), reviver);
      // Notably: first half handles `null` values somehow being there, latter half handles strings/numbers/etc.
      if (!cache || typeof cache !== 'object') {
        throw new Error('Decompression failed - manual catch');
      }
    } catch (error) {
      cache = {};
      writeCache();
      console.warn(
        'Cache did not start with `{` and JSON parsing failed; something went wrong. Defaulting to empty cache; original error:'
      );
      console.warn(error);
    }
  } else {
    cache = {};
    writeCache();
  }
}
// Map is not serializable, so we need to convert it to an array
function replacer(key: any, value: any) {
  if (value instanceof Map) {
    return {
      dataType: 'Map',
      value: Array.from(value.entries()), // or with spread: value: [...value]
    };
  } else {
    return value;
  }
}

function reviver(key: any, value: any) {
  if (typeof value === 'object' && value !== null) {
    if (value.dataType === 'Map') {
      return new Map(value.value);
    }
  }
  return value;
}

function writeCache() {
  if (typeof window === 'undefined') {
    return;
  }
  try {
    let toWrite = JSON.stringify(cache, replacer);
    toWrite = LZString.compress(toWrite);
    localStorage.setItem(LOCAL_STORAGE_KEY, toWrite);
  } catch (error) {
    console.error('Failed to write getStudents cache to local storage:', error);
  }
}

export async function getStudents(input?: GetStudentsInput): Promise<StudentList> {
  try {
    initCache();
    if (!cache) {
      throw new Error('Cache not initialized');
    }
    // Allow no params to be a query for just the current school year
    if (!input) input = {};
    if (!input.schoolYear) input.schoolYear = await getLatestYearFull();

    const query = JSON.stringify(input);
    const cacheEntry = cache.studentList?.get(query);
    if (
      cacheEntry &&
      cacheEntry.students.length > 0 &&
      cacheEntry.expiry &&
      cacheEntry.expiry > dayjs().valueOf()
    ) {
      return cacheEntry.students;
    }

    const apiName = process.env.NEXT_PUBLIC_DASH_API_NAME;
    if (!apiName) {
      throw new Error('API name is not defined');
    }

    const response = await API.get(apiName, 'student', {
      queryStringParameters: input,
    });

    const json = response.result.data.json;
    json.sort((a: any, b: any) => {
      if (a.name > b.name) return 1;
      return a.name < b.name ? -1 : 0;
    });

    // Return the response data or perform additional operations
    const now = dayjs();
    if (cache.studentList == null) {
      cache.studentList = new Map<string, StudentListCacheEntry>();
    }
    cache.studentList.set(query, { students: json, expiry: now.valueOf() + CACHE_TTL });
    writeCache();
    return json as StudentList;
  } catch (error) {
    // Handle any errors that occur during the API call
    console.error(error);
    throw error;
  }
}
