import { API } from '@aws-amplify/api';
import { ReportedQuestion } from 'common/@types/test-coaching';


export async function reportQuestion(input: ReportedQuestion): Promise<any> {
  try {
    const apiName = process.env.NEXT_PUBLIC_ACADEMICS_API_NAME!;
    const path = `api/coaching/report-test-coaching-question`;
    const myInit = {
      body: {
        ...input,
      },
    };
    const response = await API.post(apiName, path, myInit);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
