import { API } from 'aws-amplify';
import { CompetitionOnboardingStatusEnum } from 'common/@types/recommendations';

export type PutCompetitionOnboardingStatusInput = {
  student_id: string;
  onboarding_stage: CompetitionOnboardingStatusEnum;
  competition: string;
  competition_grade?: number;
};

export async function putCompetitionOnboardingStatus(
  input: PutCompetitionOnboardingStatusInput
): Promise<{ ixlResultFound?: boolean }> {
  try {
    const apiName = process.env.NEXT_PUBLIC_DASH_API_NAME!;
    const path = `api/competitions/onboarding/status`;
    const myInit = {
      headers: {},
      body: input,
    };
    return await API.put(apiName, path, myInit);
  } catch (error) {
    console.error('Error fetching auto roster status:', error);
    throw error;
  }
}
