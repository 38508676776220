import { API } from '@aws-amplify/api';
import { PutAppealInput } from '../../@types/appeals';

export async function putAppeal(input: PutAppealInput): Promise<void> {
  try {
    const apiName = process.env.NEXT_PUBLIC_DASH_API_NAME!;
    const path = `api/appeals/${input.appealId}`;
    const myInit = {
      queryStringParameters: {},
      body: input,
    };

    await API.put(apiName, path, myInit);
  } catch (error) {
    console.error(error);
    throw error;
  }
}
