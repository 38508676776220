import { memo } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';
//
import BackgroundIllustration from './illustration_background';

// ----------------------------------------------------------------------

function ForbiddenIllustration({ ...other }: BoxProps) {
  const theme = useTheme();

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARKER = theme.palette.primary.darker;

  return (
    <Box {...other}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 480 360">
        <BackgroundIllustration />

        {/* SUN */}
        <linearGradient
          id="SUN"
          x1="64.297"
          x2="111.109"
          y1="95.623"
          y2="142.436"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE16A" />
          <stop offset="1" stopColor="#B78103" />
        </linearGradient>

        <path
          fill="#FFC107"
          d="M111.109 140.247c24.644 0 44.623-19.979 44.623-44.624 0-24.645-19.979-44.623-44.623-44.623-24.645 0-44.624 19.978-44.624 44.623 0 24.645 19.979 44.624 44.624 44.624z"
          opacity="0.15"
        />

        <path
          fill="url(#SUN)"
          d="M111.109 119.029c12.927 0 23.406-10.479 23.406-23.406 0-12.926-10.479-23.406-23.406-23.406s-23.406 10.48-23.406 23.406c0 12.927 10.48 23.406 23.406 23.406z"
        />

        {/* CLOUDS */}
        <path
          fill={PRIMARY_MAIN}
          d="M425.621 117.222a8.267 8.267 0 00-9.599-8.157 11.129 11.129 0 00-9.784-5.87h-.403a13.23 13.23 0 00-20.365-14.078 13.23 13.23 0 00-5.316 14.078h-.403a11.153 11.153 0 100 22.293h38.68v-.073a8.279 8.279 0 007.19-8.193zM104.258 199.045a7.093 7.093 0 00-7.093-7.092c-.381.007-.761.04-1.138.097a9.552 9.552 0 00-8.425-5.026h-.343a11.348 11.348 0 10-22.012 0h-.342a9.564 9.564 0 100 19.114h33.177v-.061a7.107 7.107 0 006.176-7.032z"
          opacity="0.24"
        />

        {/* HAND */}
        <path
          fill="#FBCDBE"
          d="M278.056 248.069a5.078 5.078 0 00-3.352-1.983 5.078 5.078 0 00-3.773.97L260 255.25v-38.438a4.687 4.687 0 00-9.375 0v-5.625a4.67 4.67 0 00-4.687-4.687 4.301 4.301 0 00-.863.075h-.037a4.701 4.701 0 00-3.788 4.612v5.625a4.687 4.687 0 00-4.687-4.687 4.116 4.116 0 00-.844.075h-.019a4.675 4.675 0 00-3.825 4.612v5.625a4.67 4.67 0 00-4.687-4.687 4.301 4.301 0 00-.863.075 4.706 4.706 0 00-3.825 4.612v40.313a18.443 18.443 0 001.575 7.481A18.705 18.705 0 00241.25 281.5h3.131a18.72 18.72 0 0013.669-5.925l19.65-20.963a5.064 5.064 0 001.369-3.487 5.143 5.143 0 00-1.013-3.056z"
        />

        <path
          fill="#000"
          fillOpacity="0.24"
          fillRule="evenodd"
          d="M248.75 210.325v32.812h1.875v-31.95a4.67 4.67 0 00-4.687-4.687 4.301 4.301 0 00-.863.075 3.75 3.75 0 012.588 1.106 3.662 3.662 0 011.087 2.644zm-9.375 5.625v27.187h1.875v-26.325a4.687 4.687 0 00-4.687-4.687 4.116 4.116 0 00-.844.075 3.746 3.746 0 013.656 3.75zM230 243.137v-21.562a3.662 3.662 0 00-1.087-2.644 3.75 3.75 0 00-2.588-1.106c.284-.054.573-.079.863-.075a4.67 4.67 0 014.687 4.687v20.7H230z"
          clipRule="evenodd"
        />

        <path
          fill={PRIMARY_DARKER}
          d="M245 187.75a56.25 56.25 0 100 112.498 56.25 56.25 0 000-112.498zM196.509 244a48.473 48.473 0 0179.914-36.911l-68.334 68.334A48.161 48.161 0 01196.509 244zM245 292.491a48.161 48.161 0 01-31.423-11.58l68.334-68.334a48.475 48.475 0 01-10.781 72.281 48.473 48.473 0 01-26.13 7.633z"
        />

        <image
          width="180"
          height="300"
          x="300"
          y="30"
          transform="matrix(-1 0 0 1 699 0)"
          xlinkHref="/assets/illustrations/illustration_lean.png"
        />

        {/* 43 */}
        <path
          fill={PRIMARY_MAIN}
          d="M174.965 264.592c0-4.133-1.492-5.625-5.637-5.625h-11.373v-66.611c0-4.476-1.492-5.637-5.638-5.637h-9.172a9.866 9.866 0 00-7.948 3.974l-55.03 68.274a11.006 11.006 0 00-1.957 6.787v5.968c0 4.145 1.492 5.637 5.625 5.637h54.676v21.707c0 4.133 1.492 5.625 5.625 5.625h8.12c4.146 0 5.638-1.492 5.638-5.625v-21.707h11.434c4.414 0 5.637-1.492 5.637-5.637v-7.13zm-72.42-5.625l35.966-44.415v44.415h-35.966zM349.724 236.608h11.551c4.492 0 8.209-.775 11.15-2.326 2.995-1.551 5.214-3.69 6.658-6.417s2.165-5.856 2.165-9.385c0-3.69-.668-6.845-2.005-9.465-1.283-2.674-3.262-4.733-5.936-6.177-2.62-1.444-5.962-2.166-10.026-2.166-3.423 0-6.525.696-9.305 2.086-2.728 1.337-4.893 3.262-6.498 5.775-1.604 2.46-2.406 5.401-2.406 8.824H325.66c0-6.203 1.631-11.711 4.893-16.524 3.262-4.813 7.701-8.583 13.316-11.31 5.668-2.781 12.032-4.171 19.091-4.171 7.54 0 14.117 1.256 19.732 3.77 5.669 2.46 10.081 6.149 13.236 11.069 3.155 4.92 4.732 11.016 4.732 18.289 0 3.315-.775 6.684-2.326 10.107-1.551 3.422-3.85 6.551-6.898 9.385-3.048 2.781-6.845 5.053-11.391 6.818-4.545 1.711-9.813 2.567-15.802 2.567h-14.519v-10.749zm0 15.08V241.1h14.519c6.845 0 12.674.802 17.487 2.407 4.866 1.604 8.823 3.823 11.871 6.657 3.049 2.781 5.268 5.963 6.658 9.546 1.444 3.583 2.166 7.38 2.166 11.39 0 5.455-.989 10.321-2.968 14.599a29.61 29.61 0 01-8.262 10.749c-3.583 2.941-7.781 5.16-12.594 6.658-4.759 1.497-9.946 2.246-15.561 2.246-5.027 0-9.84-.696-14.439-2.086s-8.716-3.449-12.353-6.176c-3.636-2.781-6.524-6.23-8.663-10.348-2.085-4.171-3.128-8.984-3.128-14.439h19.332c0 3.476.802 6.551 2.406 9.225 1.658 2.62 3.957 4.679 6.898 6.177 2.995 1.497 6.418 2.245 10.268 2.245 4.064 0 7.567-.721 10.508-2.165 2.941-1.444 5.187-3.583 6.738-6.417 1.604-2.835 2.406-6.257 2.406-10.268 0-4.545-.882-8.235-2.647-11.069-1.765-2.835-4.278-4.92-7.54-6.257-3.262-1.39-7.112-2.086-11.551-2.086h-11.551z"
        />
      </svg>
    </Box>
  );
}

export default memo(ForbiddenIllustration);
