import { API } from '@aws-amplify/api';
import LZString from 'lz-string';
import { getAllDays } from './getAllDays';
import { formatDayjs } from '../formatTime';
import { merged_time_and_activity } from '../../@types/prisma';
import dayjs from '@common/@types/dayjs-custom';

// How long an item in the cache should stay before being invalidated
const CACHE_TTL = 1000 * 60 * 15; // in ms

// LocalStorage key
const LOCAL_STORAGE_KEY = 'dash-time-and-activity-cache';

interface TimeAndActivityCacheEntry {
  expiry: number;
  data: merged_time_and_activity[];
}
interface Cache {
  [key: string]: TimeAndActivityCacheEntry;
}

interface NeededCache {
  [key: string]: {
    fromTime: number;
    toTime: number;
  };
}

// Where data is stored in memory
let cache: Cache | null = null;

// Ensure above object is initialized; note compression algorithm integration
function initCache() {
  if (cache) return;

  const cacheRaw = localStorage?.getItem(LOCAL_STORAGE_KEY);

  // Safety: in case we still have JSON, just parse it
  if (cacheRaw?.[0] === '{') cache = JSON.parse(cacheRaw);
  // Otherwise, assume it's LZString-encoded and decompress it
  else if (cacheRaw) {
    try {
      cache = JSON.parse(LZString.decompress(cacheRaw));

      // Notably: first half handles `null` values somehow being there, latter half handles strings/numbers/etc.
      if (!cache || typeof cache !== 'object') {
        throw new Error('Decompression failed - manual catch');
      }
    } catch (error) {
      cache = {};
      console.warn(
        'Cache did not start with `{` and JSON parsing failed; something went wrong. Defaulting to empty cache; original error:'
      );
      console.warn(error);
    }
  } else cache = {};
}

export function clearTandaCache() {
  cache = {};
  writeCache(); // Make sure the localStorage cache is cleared
}

// Note compression algorithm integration
function writeCache() {
  let toWrite = JSON.stringify(cache);
  toWrite = LZString.compress(toWrite);
  localStorage.setItem(LOCAL_STORAGE_KEY, toWrite);
}

const loadingPromises = [] as unknown as Promise<void>[];

export async function getTimeAndActivity({
  fromDate,
  toDate,
  studentIds,
  schoolYear,
  session,
  week,
}: {
  fromDate: string | undefined;
  toDate: string | undefined;
  studentIds: string[];
  schoolYear: string | undefined;
  session: string | undefined;
  week: string | undefined;
}): Promise<merged_time_and_activity[]> {
  if (!studentIds || studentIds.length === 0) {
    return [];
  }

  try {
    // Because `localStorage` sometimes isn't available in Next
    if (typeof window !== 'undefined') {
      initCache();
    }

    // For TypeScript's sake
    if (!cache) {
      throw new Error('Cache not initialized');
    }

    // So that nothing else tries to duplicate a load, wait for any currently
    // running promises to finish, and eventually, add another promise to the
    // stack to make other loads wait for this one
    const currentWait = Promise.all<void>(loadingPromises.slice(0));

    // This has to be created as an immediately-called function, otherwise `process.env`
    // throws a fit.
    const curPromise = (async function () {
      await currentWait;

      try {
        // For TypeScript's sake
        if (!cache) {
          throw new Error('Cache not initialized');
        }

        // If this was called with schoolYear/session/week combo, map to fromDate/toDate
        if (!fromDate && !toDate) {
          if (!schoolYear && !session && !week) {
            throw new Error(
              'Must provide (fromDate and toDate) or (schoolYear and session and week)'
            );
          }

          const { weekInfo } = await getAllDays();
          const weekData = weekInfo[schoolYear as string][session as string][week as string];
          if (!weekData) return; // we return here, because otherwise we get an error when we try to access weekData[0].day_of and after that nothing will work
          fromDate = weekData[0].day_of.split('T')[0];
          toDate = weekData[weekData.length - 1].day_of.split('T')[0];
        }

        // Clean out expired cache items so that we're writing a minimum to localStorage
        cleanExpired();

        // First: determine which student/date combinations are not in the cache or have expired:
        const needed: NeededCache = {};

        const now = dayjs();
        for (const studentId of studentIds) {
          let curDate = dayjs(fromDate);
          const endDate = dayjs(toDate);

          // Increment dates and generate cache keys off the current date
          while (!curDate.isAfter(endDate)) {
            const cacheKey = `${studentId}|${formatDayjs(curDate)}`;
            const curTime = curDate.valueOf();

            // Done with the date, so increment it:
            curDate = curDate.add(1, 'day');
            // If this is in the cache and hasn't expired, skip processing
            if (cacheKey in cache && cache[cacheKey].expiry > now.valueOf()) continue;

            // Init dates if we haven't needed anything for this student yet
            if (!(studentId in needed)) {
              needed[studentId] = { fromTime: curTime, toTime: curTime };
            } else {
              // Otherwise, expand dates as necessary
              if (curTime < needed[studentId].fromTime) {
                needed[studentId].fromTime = curTime;
              }
              if (curTime > needed[studentId].toTime) {
                needed[studentId].toTime = curTime;
              }
            }
          }
        }

        // Make simultaneous requests for all students with different date ranges.
        // If there are no keys in `neededByDate`, this no-ops and resolves immediately.
        const promises = [] as unknown as Promise<void>[];

        const apiName = process.env.NEXT_PUBLIC_DASH_API_NAME!;
        const path = '/time-and-activity';
        for (const studentId in needed) {
          const { fromTime, toTime } = needed[studentId];

          const fromDateReference = dayjs(fromTime); // convert to date
          const toDateReference = dayjs(toTime); // convert to date

          // Standardize the fromDate to the start of the quarter and toDate to the end of the quarter
          const fromDate = fromDateReference.startOf('quarter');
          const fromDateString = formatDayjs(fromDate);
          // Take into account the fact that the toDate might be in the next quarter
          const toDate = toDateReference.endOf('quarter');
          const toDateString = formatDayjs(toDate);

          promises.push(
            (async function () {
              // Unpack dates/studentIDs and make params
              const myInit = {
                headers: {},
                queryStringParameters: {
                  startDate: fromDateString,
                  endDate: toDateString,
                  students: [studentId],
                },
              };

              const response = await API.get(apiName, path, myInit);

              const json = response.result.data.json;
              // For each real piece of data: add it to the cache
              for (const res of json) {
                const cacheKey = `${res.campus_and_student_id}|${res.date}`;

                if (!(cacheKey in cache)) {
                  // initialize cache entry
                  cache[cacheKey] = { expiry: now.valueOf() + CACHE_TTL, data: [res] };
                } else {
                  // Update the expiry
                  cache[cacheKey].expiry = now.valueOf() + CACHE_TTL;
                  // Perform a subject-based replacement of the data array
                  const index = cache[cacheKey].data.findIndex(
                    (item) => item.subject === res.subject
                  );
                  if (index > -1) {
                    cache[cacheKey].data[index] = res;
                  } else {
                    cache[cacheKey].data.push(res);
                  }
                }
              }

              // Fill in all missing data with valid timestamps but empty data
              // arrays to prevent them from trying to reload
              let curDate = dayjs(fromDate);
              const endDate = dayjs(toDate);
              while (!curDate.isAfter(endDate)) {
                const cacheKey = `${studentId}|${formatDayjs(curDate)}`;
                curDate = curDate.add(1, 'day');
                if (cacheKey in cache) {
                  // skip ones that existed in the cache
                  continue;
                } else {
                  cache[cacheKey] = { expiry: now.valueOf() + CACHE_TTL, data: [] };
                }
              }
            })()
          );
        }

        // Locally: wait for all the above loads to complete
        await Promise.all(promises);

        // Now that the loads are done, write the cache to localStorage
        if (typeof window !== 'undefined') writeCache();
      } catch (error) {
        throw error;
      }
    })();

    // Put that on the stack to delay future queued calls...
    loadingPromises.push(curPromise);
    // ...and await it.
    try {
      await curPromise;
    } catch (error) {
      console.error(error);

      // ...and remove it from what we eventually Promise.all if something went wrong.
      // Error can be swalloed here and the cache can be processed without an issue.
      loadingPromises.splice(loadingPromises.indexOf(curPromise), 1);

      // then throw it, so the parent caller can retry it
      throw error;
    }

    // By the time all pre-req promises resolve, we can guarantee that everything
    // we need (that the server knows about) is in the cache.
    const json: merged_time_and_activity[] = [];
    for (const studentId of studentIds) {
      let curDate = dayjs(fromDate); // will always be set to YYYY-MM-DD by this point, but TypeScript wants to complain
      const endDate = dayjs(toDate);
      while (!curDate.isAfter(endDate)) {
        const cacheKey = `${studentId}|${formatDayjs(curDate)}`;

        // safety, but possible to encounter with certain backend errors
        if (cacheKey in cache)
          cache[cacheKey].data.forEach((d: merged_time_and_activity) => json.push(d));

        curDate = curDate.add(1, 'day');
      }
    }

    return json;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

function cleanExpired() {
  const now = dayjs().valueOf();
  for (const key in cache) {
    if (cache[key].expiry < now) delete cache[key];
  }
}

// Update the cache/localStorage on a CACHE_TTL basis
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const cacheCleanInterval = setInterval(() => {
  cleanExpired();
  if (typeof window !== 'undefined') writeCache();
}, CACHE_TTL);
