import { API } from 'aws-amplify'; // AI-GEN - Cursor - Claude3 - Opus

export type PutMapStatusInput = {
  // AI-GEN - Cursor - Claude3 - Opus
  student_id: string; // AI-GEN - Cursor - Claude3 - Opus
  subject: string; // AI-GEN - Cursor - Claude3 - Opus
}; // AI-GEN - Cursor - Claude3 - Opus

export async function putMapStatus( // AI-GEN - Cursor - Claude3 - Opus
  input: PutMapStatusInput // AI-GEN - Cursor - Claude3 - Opus
): Promise<any> {
  // AI-GEN - Cursor - Claude3 - Opus
  try {
    // AI-GEN - Cursor - Claude3 - Opus
    const apiName = process.env.NEXT_PUBLIC_DASH_API_NAME!; // AI-GEN - Cursor - Claude3 - Opus
    const path = `api/testing/map/status`; // AI-GEN - Cursor - Claude3 - Opus
    const myInit = {
      // AI-GEN - Cursor - Claude3 - Opus
      headers: {}, // AI-GEN - Cursor - Claude3 - Opus
      body: input, // AI-GEN - Cursor - Claude3 - Opus
    }; // AI-GEN - Cursor - Claude3 - Opus
    return await API.put(apiName, path, myInit); // AI-GEN - Cursor - Claude3 - Opus
  } catch (error) {
    // AI-GEN - Cursor - Claude3 - Opus
    console.error('Error updating map status:', error); // AI-GEN - Cursor - Claude3 - Opus
    throw error; // AI-GEN - Cursor - Claude3 - Opus
  } // AI-GEN - Cursor - Claude3 - Opus
} // AI-GEN - Cursor - Claude3 - Opus
