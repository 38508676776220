import { createContext, ReactNode, useEffect, useState } from 'react';

export interface EmbedFilterParams {
  [key: string]: string;
}

import { useAppSelector } from '@common/hooks/useAppSelector';

export interface DateDetails {
  schoolYear: string;
  session: number;
  week: number;
  date: string;
}

export type DashboardSelectionContextProps = {
  filterValues: EmbedFilterParams;
  onUpdateFilterValues: (updatedFilters: EmbedFilterParams) => void;
  wrappedValue: boolean;
  onUpdateWrappedValue: (updatedWrappedValue: boolean) => void;
};

const initialState: DashboardSelectionContextProps = {
  filterValues: {},
  onUpdateFilterValues: () => {},
  onUpdateWrappedValue: () => false,
  wrappedValue: false,
};

const DashboardSelectionContext = createContext(initialState);

type DashboardSelectionProviderProps = {
  children: ReactNode;
};

const FILTER_VALUES_KEY = 'filterValues';
const VIEW_WRAPPED_KEY = 'hasViewedWrapped';

const getInitialState = () => {
  if (typeof window !== 'undefined') {
    const persistedFilterValues = localStorage.getItem(FILTER_VALUES_KEY);
    return persistedFilterValues ? JSON.parse(persistedFilterValues) : {};
  }
};

const getViewWrappedState = () => {
  if (typeof window !== 'undefined') {
    const persistedWrappedValue = localStorage.getItem(VIEW_WRAPPED_KEY);
    return persistedWrappedValue ? JSON.parse(persistedWrappedValue) : true;
  }
};

function DashboardSelectionProvider({ children }: DashboardSelectionProviderProps) {
  const dateDetailOriginal = useAppSelector((state) => state.dashboardFilterSelections.dateDetails);

  // TypeScript conversion
  const dateDetailSelections: DateDetails = {
    schoolYear: dateDetailOriginal.schoolYear,
    session: parseInt(dateDetailOriginal.session),
    week: parseInt(dateDetailOriginal.week),
    date: dateDetailOriginal.date,
  };

  // TS/eslint disables here are because `setDateDetails` isn't used, and we don't
  // wanna risk breaking things by doing `dateDetails = dateDetailSelections`.
  // @ts-ignore
  // eslint-disable-next-line
  const [dateDetails, setDateDetails] = useState<DateDetails>(dateDetailSelections);
  const [persistedFilterValues, setPersistedFilterValues] = useState(getInitialState());
  const [wrappedValue, setWrappedValue] = useState(getViewWrappedState());
  const filterValues = {
    ...dateDetails,
    ...persistedFilterValues,
  };

  useEffect(() => {
    localStorage.setItem(VIEW_WRAPPED_KEY, JSON.stringify(wrappedValue));
  }, [wrappedValue]);

  const updateFilterValues = (updatedFilters: EmbedFilterParams) => {
    const merged = { ...persistedFilterValues, ...updatedFilters };

    // Used to be in a separate useEffect triggered by `setPersistedFilterValues` but
    // that triggered a reload before it actually got set. This now sets and
    // persists correctly.
    localStorage.setItem(FILTER_VALUES_KEY, JSON.stringify(merged));
    setPersistedFilterValues(merged);
  };

  const updateViewWrappedValue = (updatedValue: boolean) => {
    // Update to true at 11:30 AM
    setWrappedValue(true);
  };

  if (dateDetails) {
    return (
      <DashboardSelectionContext.Provider
        value={{
          filterValues: filterValues,
          onUpdateFilterValues: updateFilterValues,
          wrappedValue: wrappedValue,
          onUpdateWrappedValue: updateViewWrappedValue,
        }}
      >
        {children}
      </DashboardSelectionContext.Provider>
    );
  } else {
    return <span />;
  }
}

export { DashboardSelectionProvider, DashboardSelectionContext };
