import { API } from 'aws-amplify';
export interface StudentRoster {
  record_id?: number;
  student_id: string;
  full_name: number;
  subject: string;
  recommended_course: string;
  recommended_app: string;
  reason_for_placement: string;
  last_updated_by: string | null;
  created_on: string;
  level: string;
  recommended_url: string;
}

export interface GetStudentRosterInput {
  studentId: string;
  subject: string;
  app: string;
  level: string;
  campus: string;
}

export async function getStudentRoster(input: GetStudentRosterInput): Promise<StudentRoster[]> {
  const apiName = process.env.NEXT_PUBLIC_ACADEMICS_API_NAME!;
  const path = `api/management/get-student-roster`;
  const myInit = {
    headers: {},
    queryStringParameters: {
      studentId: input.studentId,
      subject: input.subject,
      app: input.app,
      level: input.level,
      campus: input.campus,
    },
  };
  return await API.get(apiName, path, myInit);
}
