import { API } from '@aws-amplify/api';

export async function deleteInfraction({ infractionId }: { infractionId: string }): Promise<void> {
  try {
    const apiName = process.env.NEXT_PUBLIC_DASH_API_NAME!;
    const path = `api/infractions/${infractionId}`;
    const myInit = {
      queryStringParameters: {
        infractionId: infractionId,
      },
    };

    await API.del(apiName, path, myInit);
  } catch (error) {
    console.error(error);
    throw error;
  }
}
