import { memo } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

function DocIllustration({ ...other }: BoxProps) {
  const theme = useTheme();

  const PRIMARY_MAIN = theme.palette.primary.main;

  return (
    <Box {...other}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 691 508">
        <g transform="translate(-478 -425)">
          <g transform="translate(478 425)">
            <image
              width="691"
              height="508"
              x="0"
              y="0"
              xlinkHref="/assets/illustrations/illustration_doc.png"
            />
            <path
              fill={PRIMARY_MAIN}
              d="M511 293a8 8 0 018 8v8a8 8 0 01-8 8h-4a8 8 0 01-8-8v-8a8 8 0 018-8h4zM158 24a8 8 0 018 8v18a8 8 0 01-8 8h-14a8 8 0 01-8-8V32a8 8 0 018-8h14z"
            />
          </g>
        </g>
      </svg>
    </Box>
  );
}

export default memo(DocIllustration);
