import { createContext, ReactNode, useEffect, useState } from 'react';

export const IS_SURVEY_VIEWED = 'isS3SurveyViewedS4';
type LocalStorageItem = {
  value: boolean;
  toggleFunction: VoidFunction;
};

type UserFlagContextProps = {
  [key: string]: LocalStorageItem;
};

type UserFlagContextProviderProps = {
  children: ReactNode;
};

const getLocalStorageBooleanValue = (key: string) => {
  if (typeof window !== 'undefined') {
    const isSurveyViewed = localStorage.getItem(key);
    return isSurveyViewed ? JSON.parse(isSurveyViewed) : false;
  }
};

const initialState: UserFlagContextProps = {};

const UserFlagContext = createContext(initialState);

function UserFlagContextProvider({ children }: UserFlagContextProviderProps) {
  const [isSurveyViewed, setIsSurveyViewed] = useState(
    getLocalStorageBooleanValue(IS_SURVEY_VIEWED)
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem(IS_SURVEY_VIEWED, JSON.stringify(isSurveyViewed));
    }
  }, [isSurveyViewed]);

  const handleSurveyViewed = () => {
    setIsSurveyViewed(true);
  };

  const userFlagContext = {
    [IS_SURVEY_VIEWED]: {
      value: isSurveyViewed,
      toggleFunction: handleSurveyViewed,
    },
  };

  return <UserFlagContext.Provider value={userFlagContext}>{children}</UserFlagContext.Provider>;
}

export { UserFlagContext, UserFlagContextProvider };
