import React, { createContext, ReactNode, ReactElement } from 'react';
import { DefaultGuardProps, BlacklistedAttributeGuardProps } from '@common/@types/guards';
import { AccountPopoverOption, NavConfig } from '@common/@types/nav';

/**
 * Welcome to Andrew's final TypeScript/React exam. I've reached nirvana.
 *
 * As is usual with my commenting, follow line by line through the file. 50% change that this'll
 * help you learn React/TypeScript; 50% that this'll just leave you even more confused.
 *
 * I wish you luck.
 */

/**
 * The problem we need to solve here is that we have some things used by the Layout stack
 * that diverge between apps, notably a) the Guard handling and b) the route config.
 *
 * This typedef is for all the things that we'll eventually expose to components lower in the
 * React tree. `getTypedGuard` will be explained later, but for now, recognize that those
 * `input` values are the names of the Guards, and the function it returns is effectively
 * a React function component: it takes an input Props object and returns a ReactElement.
 */
export type LayoutContextProps = {
  getTypedGuard: (
    input: 'AuthGuard' | 'BlacklistedAttributeGuard' | 'GuestGuard' | 'MobileGuard' | 'PageGuard'
  ) => (input: DefaultGuardProps | BlacklistedAttributeGuardProps) => ReactElement<any, any>;
  accountPopoverOptions: AccountPopoverOption[];
  navConfig?: NavConfig[];
  Logo: (props: any) => ReactElement<any, any>;
  logoHeight: string; // css value like '0px', not `0`
};

/**
 * React Contexts are a little confusing, but you can think of them as an upstream source
 * of common resources. They require an initial state, and in TypeScript, that means we
 * need to enumerate the values in a way compatible with a type definition.
 *
 * I brushed `getTypedGuard` away as something that returns a React component - note that
 * this dummy value is technically a valid function component, as it takes input and returns
 * an (empty) React element. Logo is the same, and the other three are just defaults.
 */
const initialState: LayoutContextProps = {
  getTypedGuard:
    (guardName: string) => (input: DefaultGuardProps | BlacklistedAttributeGuardProps) => <></>,
  accountPopoverOptions: [],
  navConfig: [],
  Logo: () => <></>,
  logoHeight: '0px',
};

/**
 * This creates a context with those initial values. The minimum amount you need to know to
 * get something like this working is that this is (abbreviated, but) valid React:
 *
 * ```js
 * const context = createContext({
 *    test: 123
 * });
 * const useLayoutContext = useContext(context)
 * const {test} = useLayoutContext();
 * console.log(test); // 123
 * ```
 *
 * Our logic is _far_ more complex than that, but that should explain how things work: you
 * create a context with an initial state; `useContext()` returns a hook; `hook()` (effectively)
 * returns a reference to the current value of the context.
 *
 * See the following React doc for more info:
 * https://react.dev/reference/react/createContext
 */
const LayoutProviderContext = createContext(initialState);

/**
 * And here is where this gets stupid complicated. We need to embed the context at some
 * point _very_ high up in the React tree to enable the hook to be called.
 *
 * We set an initial state above, but it's not _quite_ right, as those are sorta placeholders
 * that we need to overwrite at compile/run time within the current application.
 *
 * To do so, `createContext().Provider` is a React component exposed by the context API
 * that can be nested in the React tree. We need to create the component, though, and we're
 * in TypeScript, so that means we need type defs.
 *
 * Note that the first six props are effectively the same as above. `getTypedGuard`, which
 * I promise I'll explain soon, looks a bit different, but that's because TypeScript let
 * me get away with this in this object.
 *
 * The last five props are the actual guards we need to pass into the React component, and
 * are passed through as props on the LayoutContext this file exposes. You can see this in
 * action in any of the `_app.tsx` files.
 */
type GuardProviderProps = {
  children?: ReactNode;
  getTypedGuard?: (input: string) => ReactElement<any, any>;
  accountPopoverOptions: AccountPopoverOption[];
  navConfig?: NavConfig[];
  Logo: (props: any) => ReactElement<any, any>;
  logoHeight: string;
  // -----
  AuthGuard: (input: DefaultGuardProps) => ReactElement<any, any>;
  BlacklistedAttributeGuard: (input: BlacklistedAttributeGuardProps) => ReactElement<any, any>;
  GuestGuard: (input: DefaultGuardProps) => ReactElement<any, any>;
  MobileGuard: (input: DefaultGuardProps) => ReactElement<any, any>;
  PageGuard: (input: DefaultGuardProps) => ReactElement<any, any>;
};

/**
 * This is the React component that uses the above typedef. Explained bit by bit as we go through.
 */
function LayoutProvider({
  children,
  AuthGuard,
  BlacklistedAttributeGuard,
  GuestGuard,
  MobileGuard,
  PageGuard,
  accountPopoverOptions,
  navConfig,
  Logo,
  logoHeight,
}: GuardProviderProps) {
  /**
   * So. There's a chance that, at any point, a Guard could be falsy. This could be systemic, in
   * that they're not used in a given app, or it could be a runtime issue, in that they're not
   * defined (or ready) at a certain point in the app instantiation process.
   *
   * To make TypeScript happy, this object is set up to cast all the objects as the React
   * component signatures they're expected to be usable as in the tree. This will never run into
   * runtime errors, since they'll be instantiable at the time they're necessary, but as part of
   * this work, I've come to realize that TypeScript actually _is_ smarter than me.
   *
   * Most of the time.
   */
  const typedGuards = {
    AuthGuard: AuthGuard as (input: DefaultGuardProps) => ReactElement<any, any>,
    BlacklistedAttributeGuard: BlacklistedAttributeGuard as (
      input: BlacklistedAttributeGuardProps
    ) => ReactElement<any, any>,
    GuestGuard: GuestGuard as (input: DefaultGuardProps) => ReactElement<any, any>,
    MobileGuard: MobileGuard as (input: DefaultGuardProps) => ReactElement<any, any>,
    PageGuard: PageGuard as (input: DefaultGuardProps) => ReactElement<any, any>,
  };

  /**
   * And so, instead of:
   * ```js
   * const {AuthGuard} = useLayoutContext()
   * ```
   * ...you have to use:
   * ```js
   * const {getTypedGuard) = useLayoutContext()
   * const AuthGuard = getTypedGuard('AuthGuard')
   *
   * This may be an artifact of where I was in the development process, and it might not actually
   * need to be this complicated. For now, it works, and it's not a bad tutorial on "how to
   * lean into TypeScript being stupid in order to understand React."
   */
  function getTypedGuard(guardName: keyof typeof typedGuards) {
    return typedGuards[guardName];
  }

  /**
   * QED for this entire stack: if you need to use a context, it requires two things:
   * - A `createContext().Provider` where the initial state (and its updates) are stored
   * - A `useLayoutContext = useContext(createContext)` hook that is called _somewhere_ below it in the tree
   *
   * That tree is the `{children}` parameter below. Anything in there (so, in `_app.tsx`, anything
   * below the `LayoutContext` component) can call `useLayoutContext()` and get access to anything
   * exposed in the `value` prop below.
   *
   * I hope you've enjoyed this tutorial. I hope even more that you understood it.
   */
  return (
    <LayoutProviderContext.Provider
      value={{
        getTypedGuard,
        accountPopoverOptions,
        navConfig,
        Logo,
        logoHeight,
      }}
    >
      {children}
    </LayoutProviderContext.Provider>
  );
}

export { LayoutProvider, LayoutProviderContext };
