import { API } from 'aws-amplify';
import { Recommendation } from '../../@types/recommendations';

export interface StudentForTestAssignment {
  student_id: string;
  subject_id: number;
  subject_name: string;
  completed_grade_level: string;
  student_name: string | null;
  latest_test_date: string | null;
  mastered_grade_level: string;
  recent_subject_recommendations: Recommendation[];
  current_subject_recommendations: Recommendation[];
  recent_test_results: EdulasticTestResult[];
  current_tests: Recommendation[];
}

export interface EdulasticTestResult {
  id: number;
  status: string;
  startDate: string;
  endDate: string;
  graded: boolean;
  maxScore: number;
  score: number;
  title: string;
  _id: string;
  username: string;
  firstName: string;
  lastName: string;
  minutesSpent: number;
  accuracy: number;
  name: string;
  testType: string;
  grade: number;
  subject: string;
  highest_mastered_grade: number | null;
  course: string;
  student_id: number | null;
  created_at: string;
}

export async function getStudentsForTestAssignment(): Promise<StudentForTestAssignment[]> {
  try {
    const apiName = process.env.NEXT_PUBLIC_ACADEMICS_API_NAME!;
    const path = `api/testing/students-for-test-assignment`;
    const response = await API.get(apiName, path, {});
    return response.students ?? [];
  } catch (error) {
    console.error(error);
    throw error;
  }
}
