import { API } from '@aws-amplify/api';
import dayjs from '@common/@types/dayjs-custom';
import LZString from 'lz-string';

// Used for both getting dates in a schoolYear/session/week combo
// and for getting schoolYear/session/week associated with a specific
// date

export interface GetAllDaysInput {
  schoolYear?: string;
  session?: number;
  week?: number;
  date?: string;
}

export interface GetAllDaysOutput {
  day_of: string;
  school_year: string;
  session: number;
  week_num: number;
  is_weekday: boolean;
  is_holiday: boolean;
  percent_through_session: number;
}

export interface GetAllDaysOutputWeekInfo {
  [key: string]: GetAllDaysOutputSchoolYear;
}
export interface GetAllDaysOutputSchoolYear {
  [key: string]: GetAllDaysOutputSession;
}

export interface GetAllDaysOutputSession {
  [key: string]: GetAllDaysOutput[];
}

export interface GetAllDaysOutputDayInfo {
  [key: string]: GetAllDaysOutput;
}

export interface GetAllDaysFnOutput {
  dayInfo: GetAllDaysOutputDayInfo;
  weekInfo: GetAllDaysOutputWeekInfo;
}

// How long an item in the cache should stay before being invalidated
const CACHE_TTL = 1000 * 60 * 60; // in ms

// LocalStorage key
const LOCAL_STORAGE_KEY = 'dash-date-cache';

let loadingPromise: null | Promise<DateCacheType> = null;

interface DateCacheType extends GetAllDaysFnOutput {
  expiry: number;
}

let dateCache: DateCacheType | null = null;

function computeLatestYearFromDateCache(cache: DateCacheType) {
  // Lookup from int version to actual key // AI-GEN - Cursor - GPT4
  const lookup: { [key: number]: string } = {}; // AI-GEN - Cursor - GPT4
  for (const key in cache.weekInfo) {
    // AI-GEN - Cursor - GPT4
    lookup[parseInt(key)] = key; // AI-GEN - Cursor - GPT4
  } // AI-GEN - Cursor - GPT4

  // Parse "22-23" as ints for the first years // AI-GEN - Cursor - GPT4
  const yearsAsInts = Object.keys(lookup) // AI-GEN - Cursor - GPT4
    .map((a) => parseInt(a)) // AI-GEN - Cursor - GPT4
    .sort(); // AI-GEN - Cursor - GPT4
  // Get the original string version // AI-GEN - Cursor - GPT4
  return lookup[Math.max.apply(null, yearsAsInts)]; // AI-GEN - Cursor - GPT4
}

function isCacheValid(cache: DateCacheType) {
  if (Object.keys(cache.dayInfo).length === 0) {
    return false;
  }
  const latestYear = computeLatestYearFromDateCache(cache);
  if (latestYear == null) {
    return false;
  }
  const yearCache = cache.weekInfo[latestYear];
  if (Object.keys(yearCache).length === 0) {
    return false;
  }
  const sessionCache = yearCache[Object.keys(yearCache)[0]];
  if (Object.keys(sessionCache).length === 0) {
    return false;
  }
  const weekCache = sessionCache[Object.keys(sessionCache)[0]];
  if (weekCache.length === 0) {
    return false;
  }
  // check if day_of is formatted correctly
  const dayOf = weekCache[0].day_of;
  if (!dayjs(dayOf, 'YYYY-MM-DD', false).isValid()) {
    return false;
  }
  return true;
}

async function refreshDateCache() {
  let cache = await getAllDays();
  let cacheValid = isCacheValid(cache);
  if (!cacheValid) {
    // try one more time with a cleared cache
    resetDateCacheAndLoadingPromise();
    cache = await getAllDays();
    cacheValid = isCacheValid(cache);
  }
  if (!cacheValid) {
    throw new Error('Date cache could not be refreshed properly');
  }
  return cache;
}

// Gets YY-YY formatted latest year
export async function getLatestYear() {
  const cache = await refreshDateCache();
  return computeLatestYearFromDateCache(cache);
}

// Gets YYYY-YYYY formatted last year
export async function getLatestYearFull() {
  const cache = await refreshDateCache();
  const latestYear = computeLatestYearFromDateCache(cache);
  // Get the full year for the first date in this school year
  const yearCache = cache.weekInfo[latestYear];
  if (Object.keys(yearCache).length === 0) {
    throw new Error('Empty latest year cache');
  }
  const sessionCache = yearCache[Object.keys(yearCache)[0]];
  if (Object.keys(sessionCache).length === 0) {
    throw new Error('Empty latest year session cache');
  }
  const weekCache = sessionCache[Object.keys(sessionCache)[0]];
  if (weekCache.length === 0) {
    throw new Error('Empty latest year session week cache');
  }
  const fullYear = weekCache[0].day_of.split('-')[0]; // YYYY-MM-DD formatted

  // Expand that into "[year]-[year + 1]"
  return `${fullYear}-${parseInt(fullYear) + 1}`;
}

function emptyDateCache(): DateCacheType {
  dateCache = {
    dayInfo: {},
    weekInfo: {},
    expiry: 0,
  };
  return dateCache;
}

function resetDateCache() {
  const cache = emptyDateCache();
  writeCache(cache);
  return cache;
}

function initCache(): DateCacheType {
  if (typeof window === 'undefined') {
    return emptyDateCache();
  }
  if (dateCache && isCacheValid(dateCache)) {
    return dateCache;
  }

  const cacheRaw = localStorage?.getItem(LOCAL_STORAGE_KEY);

  // Safety: in case we still have JSON, just parse it
  if (cacheRaw?.[0] === '{') {
    try {
      const cache = JSON.parse(cacheRaw) as DateCacheType;
      if (cache && isCacheValid(cache)) {
        return cache;
      }
    } catch (error) {
      console.error('Failed to parse getAllDays cache from local storage in legacy format:', error);
    }
  } else if (cacheRaw) {
    // Otherwise, assume it's LZString-encoded and decompress it
    try {
      const cache = JSON.parse(LZString.decompress(cacheRaw)) as DateCacheType;
      if (cache && isCacheValid(cache)) {
        return cache;
      }
    } catch (error) {
      console.error('Failed to decompress getAllDays cache from local storage:', error);
    }
  }
  return resetDateCache();
}

async function load(input: GetAllDaysInput = {}) {
  const apiName = process.env.NEXT_PUBLIC_DASH_API_NAME!;
  const path = '/all-days/day';
  const myInit = {
    headers: {},
    queryStringParameters: input,
  };

  const cache = initCache();
  if (isCacheValid(cache) && cache.expiry > dayjs().valueOf()) {
    dateCache = cache;
    return cache;
  }

  const response = await API.get(apiName, path, myInit);
  const data = response.result.data.json;
  if (data.length === 0) {
    throw new Error('Empty getAllDays response');
  }
  const {
    dayInfo,
    weekInfo,
  }: { dayInfo: GetAllDaysOutputDayInfo; weekInfo: GetAllDaysOutputWeekInfo } = cache;
  for (const day of data) {
    const dateString: string = day.day_of.split('T')[0];
    dayInfo[dateString] = day;

    if (!(day.school_year in weekInfo)) weekInfo[day.school_year] = {};
    const yearInfo: GetAllDaysOutputSchoolYear = weekInfo[day.school_year];
    if (!(day.session in yearInfo)) yearInfo[day.session] = {};
    const sessionInfo: GetAllDaysOutputSession = yearInfo[day.session];
    if (!(day.week_num in sessionInfo)) sessionInfo[day.week_num] = <GetAllDaysOutput[]>[];
    sessionInfo[day.week_num].push(day);
  }
  if (isCacheValid(cache)) {
    cache.expiry = dayjs().valueOf() + CACHE_TTL;
    writeCache(cache);
    dateCache = cache;
    return cache;
  } else {
    throw new Error('Failed to populate a valid cache from getAllDays');
  }
}

export function resetDateCacheAndLoadingPromise() {
  resetDateCache();
  loadingPromise = null;
}

function writeCache(cache: DateCacheType) {
  if (typeof window === 'undefined') {
    return;
  }
  try {
    let toWrite = JSON.stringify(cache);
    toWrite = LZString.compress(toWrite);
    localStorage.setItem(LOCAL_STORAGE_KEY, toWrite);
  } catch (error) {
    console.error('Failed to write getAllDays cache to local storage:', error);
  }
}

export async function getAllDays(input: GetAllDaysInput = {}): Promise<DateCacheType> {
  try {
    let cache;
    if (loadingPromise) {
      cache = await loadingPromise;
    } else {
      loadingPromise = load(input);
      cache = await loadingPromise;
      loadingPromise = null;
    }
    if (cache == null || !isCacheValid(cache)) {
      throw new Error('Failed to load a valid cache from getAllDays');
    }
    return cache;
  } catch (error) {
    console.error(error);
    resetDateCacheAndLoadingPromise();
    throw error;
  }
}
