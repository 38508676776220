import { API } from 'aws-amplify'; // AI-GEN - Cursor - GPT4

export interface StudentGoalView {
  // AI-GEN - Cursor - GPT4
  id: string; // AI-GEN - Cursor - GPT4
  studentId: string; // AI-GEN - Cursor - GPT4
  goalTitle?: string; // AI-GEN - Cursor - GPT4
  goal?: string; // AI-GEN - Cursor - GPT4
  goalId?: number; // AI-GEN - Cursor - GPT4
  timeframe: string; // AI-GEN - Cursor - GPT4
  timeframeId?: number; // AI-GEN - Cursor - GPT4
  subject: string; // AI-GEN - Cursor - GPT4
  fmtEndDate: string; // AI-GEN - Cursor - GPT4
  originalPace: number;
  currentPace: number | null; // AI-GEN - Cursor - GPT4
  originalLessonsLeft: number;
  currentLessonsLeft: number | null;
} // AI-GEN - Cursor - GPT4

export async function getStudentGoalByStudentId(input: {
  studentId: string;
}): Promise<StudentGoalView[]> {
  // AI-GEN - Cursor - GPT4
  try {
    // AI-GEN - Cursor - GPT4
    const apiName = process.env.NEXT_PUBLIC_DASH_API_NAME!; // AI-GEN - Cursor - GPT4
    const path = '/api/goals/get-student-goal-by-studentid'; // AI-GEN - Cursor - GPT4
    const myInit = {
      // AI-GEN - Cursor - GPT4
      headers: {}, // AI-GEN - Cursor - GPT4
      response: true, // AI-GEN - Cursor - GPT4
      queryStringParameters: {
        // AI-GEN - Cursor - GPT4
        student_id: input.studentId, // AI-GEN - Cursor - GPT4
      }, // AI-GEN - Cursor - GPT4
    }; // AI-GEN - Cursor - GPT4
    const response = await API.get(apiName, path, myInit); // AI-GEN - Cursor - GPT4
    return response.data; // AI-GEN - Cursor - GPT4
  } catch (error) {
    // AI-GEN - Cursor - GPT4
    console.error('Error fetching student goal by studentId', error); // AI-GEN - Cursor - GPT4
    throw error; // AI-GEN - Cursor - GPT4
  } // AI-GEN - Cursor - GPT4
} // AI-GEN - Cursor - GPT4
