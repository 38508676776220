import { API } from '@aws-amplify/api';
import { PostCoachingFormOptions } from '../../@types/coaching';

export async function getPostCoachingFormOptions({
  student_id,
}: {
  student_id: string;
}): Promise<PostCoachingFormOptions> {
  try {
    const apiName = process.env.NEXT_PUBLIC_DASH_API_NAME!;
    const path = `coaching/get-coaching-form-options/${student_id}`;
    const myInit = {};
    const response = await API.get(apiName, path, myInit);
    console.log('response here', response);
    return response.result;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
