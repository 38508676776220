import { API } from 'aws-amplify'; // AI-GEN - Cursor - GPT4
import { GoalInput } from './invalidateGoal';

export async function saveGoal(goalData: GoalInput): Promise<void> {
  // AI-GEN - Cursor - GPT4
  try {
    // AI-GEN - Cursor - GPT4
    const apiName = process.env.NEXT_PUBLIC_DASH_API_NAME!; // AI-GEN - Cursor - GPT4
    const path = `api/goals/save-goal`; // AI-GEN - Cursor - GPT4
    const myInit = {
      // AI-GEN - Cursor - GPT4
      headers: {}, // AI-GEN - Cursor - GPT4
      body: goalData, // AI-GEN - Cursor - GPT4
    }; // AI-GEN - Cursor - GPT4
    await API.post(apiName, path, myInit); // AI-GEN - Cursor - GPT4
  } catch (error) {
    // AI-GEN - Cursor - GPT4
    console.error('Error saving goal:', error); // AI-GEN - Cursor - GPT4
    throw error; // AI-GEN - Cursor - GPT4
  } // AI-GEN - Cursor - GPT4
} // AI-GEN - Cursor - GPT4
