import { API } from '@aws-amplify/api';
import { type TestScore } from 'common/@types/test_score';

const getSubjectInfoFromAPI = async (fromDate: string, subject: string, studentId: string) => {
  const apiName = process.env.NEXT_PUBLIC_DASH_API_NAME!;
  const path = '/test-scores';

  const myInit = {
    headers: {},
    queryStringParameters: { fromDate, subject, studentId },
  };

  const response = await API.get(apiName, path, myInit);
  const data = response?.result?.data?.json ?? [];

  return { subject, data };
};

export async function getTestScores({
  fromDate,
  subjects,
  studentId,
}: {
  fromDate: string;
  subjects: string[];
  studentId: string;
}): Promise<Record<string, TestScore[]>> {
  if (!studentId || !subjects || !subjects.length) return {};

  try {
    const promises = subjects.map((subject) => getSubjectInfoFromAPI(fromDate, subject, studentId));
    const results = await Promise.all(promises);

    return Object.fromEntries(results.map(({ subject, data }) => [subject, data]));
  } catch (error) {
    console.error(error);
    throw error;
  }
}
