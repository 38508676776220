import { API } from 'aws-amplify';
import { CompetitionOnboardingStatus } from 'common/@types/recommendations';

export async function getCompetitionOnboardingStatus(input: {
  id: string;
}): Promise<CompetitionOnboardingStatus> {
  if (!input.id)
    return {
      campus_id: '',
      student_id: '',
      full_student_id: input.id,
      onboarding_stage: 'recommendations',
      khan_account_created: 0, // AI-GEN - Cursor - GPT4
      khan_math_class_link: '', // AI-GEN - Cursor - GPT4
      original_age_grade: -2,
      screener_overall_result: null,
      competition_grade: null,
      competition: '',
      created_on: '',
      updated_on: '',
    };
  try {
    const apiName = process.env.NEXT_PUBLIC_DASH_API_NAME!;
    const path = `api/competitions/onboarding/status`;
    const myInit = {
      headers: {},
      queryStringParameters: {
        student_id: input.id,
      },
    };
    return await API.get(apiName, path, myInit);
  } catch (error) {
    console.error('Error fetching competition onboarding status:', error);
    throw error;
  }
}
