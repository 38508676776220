// hooks
import useAuth from '../hooks/useAuth';
// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar, { Props as AvatarProps } from './Avatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: AvatarProps) {
  const { user } = useAuth();

  return (
    <Avatar
      src={user?.attributes['custom:customImage']}
      alt={user?.attributes['custom:customName']}
      color={
        user?.attributes['custom:customImage']
          ? 'default'
          : createAvatar(user?.attributes['custom:customName'] as string).color
      }
      {...other}
    >
      {createAvatar(user?.attributes['custom:customName'] as string).name}
    </Avatar>
  );
}
