import { pollAmplifyAPI } from './pollAmplifyAPI';
import { SubmitRecommendations, SubmitRecommendationResult } from '../../@types/recommendations';

export async function submitTests(
  input: SubmitRecommendations
): Promise<SubmitRecommendationResult[]> {
  try {
    const apiName = process.env.NEXT_PUBLIC_ACADEMICS_API_NAME!;
    const path = 'api/recommendations/v2/test/submit';
    const myInit = {
      queryStringParameters: {},
      body: input,
    };

    const response = await pollAmplifyAPI('post', apiName, path, myInit);
    if (!response || !response.recommendations) {
      return response;
    }
    return response.recommendations;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
