import { API } from '@aws-amplify/api';
import { Videos } from '@common/@types/recommendations';

export async function getHowToVideo(data: { apps: string[] }): Promise<Videos> {
  try {
    if (!Array.isArray(data.apps) || !data.apps.every((app) => typeof app === 'string')) return {};

    const apiName = process.env.NEXT_PUBLIC_DASH_API_NAME!;
    const path = 'get-how-to-video';
    const myInit = {
      headers: {},
      body: {
        data,
      },
    };
    const response = await API.post(apiName, path, myInit);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
