import { API } from '@aws-amplify/api';
import { Antipattern } from '@common/@types/antipattern';

export async function getAntiPatternList({}): Promise<Antipattern[]> {
  try {
    const apiName = process.env.NEXT_PUBLIC_ACADEMICS_API_NAME!;
    const path = 'api/antipatterns';
    const response = await API.get(apiName, path, {});
    return response.result;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
