import { API } from 'aws-amplify';

interface LessonContentFilters {
  subject?: string;
  grade?: string;
  app?: string;
  skillCode?: string;
}

export const getLessonContent = async (filters: LessonContentFilters = {}) => {
  const apiName = process.env.NEXT_PUBLIC_ACADEMICS_API_NAME!;
  const path = 'api/lesson-content';
  const response = await API.get(apiName, path, {
    queryStringParameters: {
      subject: filters.subject,
      grade: filters.grade,
      app: filters.app,
      skillCode: filters.skillCode,
    },
  });
  return response;
};
