import { forwardRef } from 'react';
import NextLink from 'next/link';
import { Box, BoxProps } from '@mui/material';
import useLayoutContext from '@common/hooks/useLayoutContext';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<any, Props>(({ disabledLink = false, sx }, ref) => {
  const { Logo, logoHeight } = useLayoutContext();

  const logo = (
    <Box ref={ref} sx={{ width: 40, height: logoHeight, cursor: 'pointer', ...sx }}>
      <Logo />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <NextLink href={process.env.PATH_AFTER_LOGIN as string}>{logo}</NextLink>;
});

export default Logo;
