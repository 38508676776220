// import { API } from 'aws-amplify';
import siteStrings from '@common/sites/site-strings.json';
import type { SiteStrings } from '@common/@types/siteStrings';

// If we have site config verified from the server, we want to use that
let validatedSiteConfig: SiteStrings | null = null;

export async function getSiteStrings(): Promise<SiteStrings | undefined> {
  // It's not actually "validated" by the backend here, but while we're not
  // loading it from the backend, use this to cache it
  if (validatedSiteConfig) return validatedSiteConfig;

  // Get domain and TLD but not subdomain, use that to index siteConfig
  let domain = 'default';
  if (typeof window !== 'undefined') domain = window.location.hostname;
  const siteStringsKey = domain.split('.').slice(-2).join('.');

  // Return the config if it was in the JSON
  if (typeof siteStringsKey === 'string' && siteStringsKey in siteStrings) {
    const config = (siteStrings[siteStringsKey as keyof typeof siteStrings] ??
      siteStrings.default) as SiteStrings;
    validatedSiteConfig = config;
    return config;
  }

  // Otherwise, null
  return siteStrings.default as SiteStrings;
}
