import { pollAmplifyAPI } from './pollAmplifyAPI';
export interface SkillPlan {
  id?: number;
  subject: string;
  app: string;
  type: string;
  order: string | null;
  skill_id: string;
  skill_code?: string;
  permacode?: string;
  plan_grade: string;
  plan_grade_level: string;
  skill_name: string;
  supporting_skill_codes: string[];
  source: string;
  invalidated_on: string;
}

export interface UpdateSkillPlan {
  row: SkillPlan;
  newOrder?: number;
  addSkills?: string[];
  addScaffolding?: string[];
  action: string;
  reason: string;
  createdBy: string;
  invalidate?: boolean;
}

export async function updateSkillPlan(input: UpdateSkillPlan): Promise<string> {
  try {
    const apiName = process.env.NEXT_PUBLIC_ACADEMICS_API_NAME!;
    const path = 'api/management/update-skill-plan';
    const requestParameters = {
      queryStringParameters: {},
      body: input,
    };

    const response = await pollAmplifyAPI('post', apiName, path, requestParameters);
    if (!response || !response.result) {
      throw new Error(
        `Failed to get a response from ${apiName}${path} with input: ${JSON.stringify(input)}`
      );
    }
    return response.result;
  } catch (error) {
    console.error(`Failed to update skill plan: ${error.message}`);
    throw error;
  }
}
