import { API } from '@aws-amplify/api';
import { SimpleCrudFilters, SimpleCrudReturn, SimpleCrudReturnIDs } from '../../@types/crud';

export class SimpleCrudGetList<T> {
  readonly endpoint: string;

  constructor(endpoint: string) {
    this.endpoint = endpoint;
  }

  makeFullId(row: SimpleCrudReturnIDs) {
    if (!row) return '';

    const campusId = row.campus_id?.toString().padStart(3, '0');
    const studentId = row.student_id?.toString().padStart(4, '0');
    return `${campusId}-${studentId}`;
  }

  async list(input: SimpleCrudFilters): Promise<SimpleCrudReturn> {
    try {
      const apiName = process.env.NEXT_PUBLIC_ACADEMICS_API_NAME!;
      const path = `api/${this.endpoint}`;
      const myInit = {
        headers: {},
        queryStringParameters: input,
      };
      const response = await API.get(apiName, path, myInit);
      response.results.forEach((r: SimpleCrudReturnIDs) => {
        if (r[this.endpoint + '_id']) r.id = r[this.endpoint + '_id'];

        if (r.campus_id) r.full_id = this.makeFullId(r);
      });
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async get(input: { id: string }): Promise<T> {
    try {
      const apiName = process.env.NEXT_PUBLIC_ACADEMICS_API_NAME!;
      const path = `api/${this.endpoint}/${input.id}`;
      const myInit = {
        headers: {},
      };
      const response = await API.get(apiName, path, myInit);
      response.result.full_id = this.makeFullId(response.result);
      return response.result;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}
