import { API } from '@aws-amplify/api';

export type Course = {
  app: string;
  name: string;
  recommended_course_level: number;
};

export async function getCourseList(): Promise<Course[]> {
  try {
    const apiName = process.env.NEXT_PUBLIC_ACADEMICS_API_NAME!;
    const path = 'api/course';
    const response = await API.get(apiName, path, {});
    return response as Course[];
  } catch (error) {
    console.error(error);
    throw error;
  }
}
