import { ReactNode, useMemo } from 'react';
// @mui
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeOptions, ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
// hooks
import useSettings from '@common/hooks/useSettings';
//
import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import componentsOverride from './overrides';
import shadows, { DARK_MODE, LIGHT_MODE, createCustomShadow } from './shadows';
import { siteThemes } from './site-themes';

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
  themeName?: string | undefined;
};

export default function ThemeProvider({ children, themeName }: Props) {
  const { themeMode, themeDirection } = useSettings();

  const isLight = themeMode === 'light';
  const siteTheme = siteThemes[themeName || 'alpha'];
  const paletteBase = {
    ...palette[themeMode],
    ...siteTheme,
  };

  // One override we need to make sure buttons use the requested color
  paletteBase.primary.main = siteTheme.cta.primary;

  // Required override for button hover background color if it is set
  if (siteTheme.buttonPrimary?.dark) {
    paletteBase.primary.dark = siteTheme.ctaBg.primary;
  }
  if (siteTheme.buttonPrimary?.main) {
    paletteBase.primary.main = siteTheme.buttonPrimary.main;
  }
  if (siteTheme.buttonSecondary?.dark) {
    paletteBase.secondary.dark = siteTheme.ctaBg.primary;
  }
  if (siteTheme.buttonSecondary?.main) {
    paletteBase.secondary.main = siteTheme.buttonSecondary.main;
  }

  // Required override for button hover text color if it is set
  if (siteTheme.buttonPrimary?.contrastText) {
    paletteBase.primary.contrastText = siteTheme.buttonPrimary.contrastText;
  }
  if (siteTheme.buttonSecondary?.contrastText) {
    paletteBase.secondary.contrastText = siteTheme.buttonSecondary.contrastText;
  }

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette: paletteBase,
      typography,
      breakpoints,
      shape: { borderRadius: 8 },
      direction: themeDirection,
      shadows: isLight ? shadows.light : shadows.dark,
      customShadows: isLight
        ? createCustomShadow(paletteBase, LIGHT_MODE)
        : createCustomShadow(paletteBase, DARK_MODE),
    }),
    [isLight, themeName, themeDirection]
  );

  const theme = createTheme(themeOptions);

  theme.components = componentsOverride(theme);

  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MUIThemeProvider>
  );
}
