import { API } from '@aws-amplify/api';

export async function deletePostCoachingForm({
  submission_id,
}: {
  submission_id: string;
}): Promise<void> {
  try {
    const apiName = process.env.NEXT_PUBLIC_ACADEMICS_API_NAME!;
    const path = `api/coaching/delete-test-coaching-form-data/${submission_id}`;
    const myInit = {};
    await API.del(apiName, path, myInit);
  } catch (error) {
    console.error(error);
    throw error;
  }
}
