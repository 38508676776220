import { createContext, ReactNode, useEffect, useReducer } from 'react';
// @types
import Auth from '@aws-amplify/auth';
import Amplify from '@aws-amplify/core';
import { ActionMap, AuthState, AuthUser, JWTContextType } from '@common/@types/auth';
import { mixpanelService } from '@common/analytics/mixpanel';
import { getAllDays, resetDateCacheAndLoadingPromise } from '@common/utils/amplify/getAllDays';
import { clearStudentList } from '@common/utils/amplify/getStudents';
import { clearTandaCache } from '@common/utils/amplify/getTimeAndActivity';
import { IDTokenPayload, userAttributeAdapter } from '@common/utils/userAttributeAdapter';
import * as Sentry from '@sentry/nextjs';
import { amplitudeService } from 'common/analytics/amplitude';
import { clearRealtimeApiCache } from 'common/utils/amplify/getRealtimeDailyActivity';
import { useRouter } from 'next/router';
import { store } from '../redux/store';

let redirectUrl = '';
// Next "server-side" compat
if (typeof window !== 'undefined') {
  // In all cases, we're not fighting the Next redirects and just always
  // ensuring we're on /dashboard/daily-activity/ when we redirect the user
  // to SSO signin.
  redirectUrl = window.location.origin + '/dashboard/daily-activity/';
}

Amplify.configure({
  Auth: {
    region: 'us-east-1',
    userPoolId: process.env.NEXT_PUBLIC_USER_POOL_ID,
    userPoolWebClientId: process.env.NEXT_PUBLIC_USER_POOL_CLIENT_ID,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    oauth: {
      // Must not have protocol or it'll error
      domain: process.env.NEXT_PUBLIC_COGNITO_DOMAIN_URL?.replace(/https?:\/\//, ''),
      responseType: 'code',

      // Check above.
      redirectSignIn: redirectUrl,
      redirectSignOut: redirectUrl,
      scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    },
  },
  endpoints: [
    {
      name: process.env.NEXT_PUBLIC_DASH_API_NAME,
      endpoint: process.env.NEXT_PUBLIC_DASH_API_BASE,
      region: 'us-east-1',
    },
  ],
});
enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);
  const router = useRouter();

  useEffect(() => {
    const initialize = async () => {
      try {
        const user = await Auth.currentUserInfo();
        const { payload } = (await Auth.currentSession()).getIdToken();

        // Prevent further errors down the line if not yet logged in
        if (!user) {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
          return;
        }

        // Remove the code/state params from the URL in case they're there
        const { pathname, query } = router;
        if ('code' in query) {
          delete query['code'];
          delete query['state'];
          router.replace({ pathname, query }, undefined, { shallow: true });
        }

        console.log('cognito user/idToken:', user, payload);
        const authUser = await userAttributeAdapter(user, payload as IDTokenPayload);
        mixpanelService.identify(String(authUser?.attributes.email));
        amplitudeService.identify(authUser);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: true,
            user: authUser,
          },
        });
      } catch (error) {
        console.error(error);
        Sentry.captureException(error);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string) => {
    try {
      await Auth.signIn({
        username: email,
        password: password,
      });
      const user = await Auth.currentUserInfo();
      console.log('cognito user: ', user);

      const authUser = await userAttributeAdapter(user);
      mixpanelService.identify(String(authUser?.attributes.email));
      amplitudeService.identify(authUser);
      dispatch({
        type: Types.Login,
        payload: {
          user: authUser,
        },
      });
      // Possibly unneeded for Academics app - could convert to feature flag
      await getAllDays();
    } catch (error) {
      Sentry.captureException(error);
      dispatch({ type: Types.Logout });
      throw error;
    }
  };

  const register = async (email: string, password: string, firstName: string, lastName: string) => {
    const user = await Auth.signUp({
      username: email,
      password,
      attributes: {
        email: email,
        name: firstName + ' ' + lastName,
      },
      autoSignIn: {
        enabled: true,
      },
    });

    const authUser = await userAttributeAdapter(user as any);
    mixpanelService.identify(String(authUser?.attributes.email));
    mixpanelService.track('Sign Up', {
      'Signup Type': 'Referral',
    });
    dispatch({
      type: Types.Register,
      payload: {
        user: authUser,
      },
    });
  };

  const logout = async () => {
    try {
      // We want to make sure we're on `/dashboard/daily-activity/` so that any SSO signouts
      // initiate from a predictable URL that we've already whitelisted. The {shallow: true}
      // param should make sure this doesn't actually trigger any view changes.
      router.replace(process.env.PATH_AFTER_LOGIN as string, undefined, { shallow: true });

      await Auth.signOut({
        global: true,
      });

      amplitudeService.identify(null);

      clearStudentList();
      clearTandaCache();
      resetDateCacheAndLoadingPromise();
      clearRealtimeApiCache();

      // Dispatch an event to clear all dashboardFilterSelections. May not be the
      // most Redux-ey way to do it, but it works
      store.dispatch({ type: 'dashboardFilterSelections/clearAll' });

      dispatch({ type: Types.Logout });
    } catch (error) {
      amplitudeService.identify(null);
      // Clear localStorage items
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key && key.startsWith('Cognito')) {
          localStorage.removeItem(key);
        }
      }

      Sentry.captureException(error, {
        level: error.code === 'NotAuthorizedException' ? 'info' : 'error',
      });

      router.push({
        pathname: process.env.PATH_AFTER_LOGIN as string,
      });

      dispatch({ type: Types.Logout });
    }
  };

  const changePassword = async (oldPassword: string, newPassword: string) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, oldPassword, newPassword);
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
    }
  };

  const sendResetLink = async (email: string) => {
    await Auth.forgotPassword(email);
  };

  const resetPassword = async (email: string, code: string, newPassword: string) => {
    await Auth.forgotPasswordSubmit(email, code, newPassword);
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        changePassword,
        sendResetLink,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

mixpanelService.init(process.env.NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN as string, {
  debug: true,
  track_pageview: false, // We'll track these manually in pageViewTracker.tsx
  persistence: 'localStorage',
  ignore_dnt: true,
});

amplitudeService.init();

export { AuthContext, AuthProvider };
