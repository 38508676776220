import { createSlice } from '@reduxjs/toolkit';
import omit from 'lodash/omit';
// utils
import axios from '@common/utils/axios';
// @types
import { CheckChartCard, KanbanColumn, CheckChartState } from '../../@types/kanban';
//
import { dispatch } from '../store';
import getAdjustedDate from '@common/utils/getAdjustedDate';

// ----------------------------------------------------------------------

function objFromArray<Type extends Record<string, any>>(array: Type[], key = 'id') {
  return array.reduce<Record<string, Type>>((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

const initialState: CheckChartState = {
  isLoading: false,
  error: null,
  board: {
    cards: {},
    columns: {},
    columnOrder: [],
  },
};

const slice = createSlice({
  name: 'kanban',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET BOARD
    getBoardSuccess(state, action) {
      state.isLoading = false;
      const board = action.payload;
      const cards = objFromArray<CheckChartCard>(board.cards);
      const columns = objFromArray<KanbanColumn>(board.columns);
      const { columnOrder } = board;
      state.board = {
        cards,
        columns,
        columnOrder,
      };
    },

    // CREATE NEW COLUMN
    createColumnSuccess(state, action) {
      const newColumn = action.payload;
      state.isLoading = false;
      state.board.columns = {
        ...state.board.columns,
        [newColumn.id]: newColumn,
      };
      state.board.columnOrder.push(newColumn.id);
    },

    persistCard(state, action) {
      const columns = action.payload;
      state.board.columns = columns;
    },

    persistColumn(state, action) {
      state.board.columnOrder = action.payload;
    },

    addTask(state, action) {
      const { card, columnId } = action.payload;

      state.board.cards[card.id] = card;
      state.board.columns[columnId].cardIds.push(card.id);
    },

    deleteTask(state, action) {
      const { cardId, columnId } = action.payload;

      state.board.columns[columnId].cardIds = state.board.columns[columnId].cardIds.filter(
        (id) => id !== cardId
      );

      state.board.cards = omit(state.board.cards, [cardId]);
    },

    // UPDATE COLUMN
    updateColumnSuccess(state, action) {
      const column = action.payload;

      state.isLoading = false;
      state.board.columns[column.id] = column;
    },

    // DELETE COLUMN
    deleteColumnSuccess(state, action) {
      const { columnId } = action.payload;
      const deletedColumn = state.board.columns[columnId];

      state.isLoading = false;
      state.board.columns = omit(state.board.columns, [columnId]);
      state.board.cards = omit(state.board.cards, [...deletedColumn.cardIds]);
      state.board.columnOrder = state.board.columnOrder.filter((c) => c !== columnId);
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function getBoard() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        'https://minimal-assets-api-dev.vercel.app/api/kanban/board'
      );
      dispatch(slice.actions.getBoardSuccess(response?.data.board));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCheckChartBoard(boardData: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const cards = boardData
        .map((column: any) =>
          column.kanban_cards.map((card: any) => ({
            id: card.id,
            name: card.name,
            description: card.description,
            assignee: [], // Always empty for now
            due: getAdjustedDate(card.due_date),
            attachments: [], // Always empty for now
            comments: card.kanban_comments?.map((comment: any) => ({
              id: comment.id,
              avatar: comment.avatar,
              name: comment.name,
              createdAt: comment.created_at,
              messageType: comment.message_type,
              message: comment.message,
            })),
            completed: card.is_completed,
            difficulty: card.check?.check_detail.difficulty || 'easy',
            journeyUrl: card.check?.check_detail.journey_url,
            testToPassUrl: card.check?.check_detail.test_to_pass_url,
          }))
        )
        .flat();
      const columns = boardData?.map((column: any) => ({
        id: column.id,
        name: column.name,
        cardIds: column.kanban_cards.map((card: any) => card.id),
      }));
      const checksColumnId = boardData?.find((item: any) => item.name === 'Checks')?.id;
      const goalsColumnId = boardData?.find((item: any) => item.name === 'Goals')?.id;
      const columnOrder = [];
      if (checksColumnId && goalsColumnId) {
        columnOrder.push(checksColumnId, goalsColumnId);
      }

      const board = {
        cards: cards,
        columns: columns,
        columnOrder: columnOrder,
      };

      dispatch(slice.actions.getBoardSuccess(board));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createColumn(newColumn: { name: string }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/kanban/columns/new', newColumn);
      dispatch(slice.actions.createColumnSuccess(response.data.column));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateColumn(columnId: string, updateColumn: KanbanColumn) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/kanban/columns/update', {
        columnId,
        updateColumn,
      });
      dispatch(slice.actions.updateColumnSuccess(response.data.column));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteColumn(columnId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/api/kanban/columns/delete', { columnId });
      dispatch(slice.actions.deleteColumnSuccess({ columnId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function persistColumn(newColumnOrder: string[]) {
  return () => {
    dispatch(slice.actions.persistColumn(newColumnOrder));
  };
}

// ----------------------------------------------------------------------

export function persistCard(columns: Record<string, KanbanColumn>) {
  return () => {
    dispatch(slice.actions.persistCard(columns));
  };
}

// ----------------------------------------------------------------------

export function addTask({ card, columnId }: { card: Partial<CheckChartCard>; columnId: string }) {
  return () => {
    dispatch(slice.actions.addTask({ card, columnId }));
  };
}

// ----------------------------------------------------------------------

export function deleteTask({ cardId, columnId }: { cardId: string; columnId: string }) {
  return () => {
    dispatch(slice.actions.deleteTask({ cardId, columnId }));
  };
}
