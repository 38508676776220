/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull browser'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 1
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/alphaschool/Alpha/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/alphaschool/Alpha/implementation/browser)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'alpha';

export const ApiKey: Record<Environment, string> = {
  alpha: 'bdf9080c2f6527a45406705a216a2268'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '1',
    branch: 'main',
    source: 'browser',
    versionId: 'e24d9331-af1b-4893-bb25-e9b12c5323b4'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Admin, Student, Guide, Parent |
   */
  Role?: "Admin" | "Student" | "Guide" | "Parent" | "Undefined";
}

export interface AmplitudeElementChangedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Element Class"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Element ID"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Element Parent Label"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "[Amplitude] Element Position Left"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "[Amplitude] Element Position Top"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Element Selector"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Element Tag"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Page Title"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Page URL"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "[Amplitude] Viewport Height"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "[Amplitude] Viewport Width"?: number;
}

export interface AmplitudeElementClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Element Class"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Element Href"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Element ID"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Element Parent Label"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "[Amplitude] Element Position Left"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "[Amplitude] Element Position Top"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Element Selector"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Element Tag"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Element Text"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Page Title"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Page URL"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "[Amplitude] Viewport Height"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "[Amplitude] Viewport Width"?: number;
}

export interface AmplitudeFormStartedProperties {
  "[Amplitude] Form Destination"?: any;
}

export interface AmplitudeFormSubmittedProperties {
  "[Amplitude] Form Destination"?: any;
}

export interface AmplitudePageViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Page Domain"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Page Location"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Page Path"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Page Title"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Page URL"?: string;
}

export interface ClickActivityViewProperties {
  /**
   * Boolean is true if the subject clicked has 2hr Session status. For example, in the Monthly Activty view when a date is clicked
   */
  "2hr-subject"?: boolean;
  /**
   * This is used when the user is clicks on a specific date in the Monthly or Weekly view
   */
  "clicked-date"?: string;
  /**
   * The view the student clicked to change the current view
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Daily, Weekly, Monthly, Next Day, Previous Day, Next Week, Previous Week, Next Month, Previous Month |
   */
  "clicked-view"?:
    | "Daily"
    | "Weekly"
    | "Monthly"
    | "Next Day"
    | "Previous Day"
    | "Next Week"
    | "Previous Week"
    | "Next Month"
    | "Previous Month";
  /**
   * The current view the student is looking at when they click to change view
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Daily, Weekly, Monthly, Profile, My Assigned Learning |
   */
  "current-view"?: "Daily" | "Weekly" | "Monthly" | "Profile" | "My Assigned Learning" | "Coaching Summaries" | "My Goals" | "Goals";
  /**
   * The dash instance the student is using
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Esports, Alpha, Alpha Anywhere, Learn and Earn, Havruta |
   */
  dash: "Esports" | "Alpha" | "Alpha Anywhere" | "Learn and Earn" | "Havruta";
  /**
   * Boolean returns true if the view displayed no data, and instead displayed the empty state view.
   */
  "empty-state"?: boolean;
  /**
   * The subject that the link is associated with, i.e. Math, Science etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Math, Science, Reading, Writing, Language, Social Studies, Computer Science, English, Economics, Non-Core Skills, SAT Prep, American History, Biology, Calculus, Chemistry, Foreign Language, Physics, Statistics, Test Prep, World History, World Languages |
   */
  subject?:
    | "Math"
    | "Science"
    | "Reading"
    | "Writing"
    | "Language"
    | "Social Studies"
    | "Computer Science"
    | "English"
    | "Economics"
    | "Non-Core Skills"
    | "SAT Prep"
    | "American History"
    | "Biology"
    | "Calculus"
    | "Chemistry"
    | "Foreign Language"
    | "Physics"
    | "Statistics"
    | "Test Prep"
    | "World History"
    | "World Languages";
}

export interface ClickCoachingProperties {
  /**
   * The accuracy score by student for the subject they've clicked the Coach button on.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  accuracy?: number;
  /**
   * The dash instance the student is using
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Esports, Alpha, Alpha Anywhere, Learn and Earn, Havruta |
   */
  dash: "Esports" | "Alpha" | "Alpha Anywhere" | "Learn and Earn" | "Havruta";
  /**
   * The boolean property is true if the app is the only app used within a given subject.
   *
   *  For example, in Daily Activity, if Subject:Math used only App:IXL then exclusive-app would be true.
   *
   *  However if Subject:Math used both App:IXL & Struggly then exclusive-app would be false.
   */
  "exclusive-app"?: boolean;
  /**
   * The primary learning app used by student for the subject they've clicked the Coach button on. Typically this will be IXL or Khan etc.
   */
  "learning-app-1"?: string;
  /**
   * The primary learning app's level the student is on when clicking the Coach button. For example "930-1080L"
   */
  "learning-app-1-level"?: string;
  /**
   * The number of skills mastered by student on the primary app for the subject they've clicked the Coach button on.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "learning-app-1-mastered"?: number;
  /**
   * The secondary learning app used by student for the subject they've clicked the Coach button on. Typically this will be IXL or Khan etc.
   */
  "learning-app-2"?: string;
  /**
   * The secondary learning app's level the student is on when clicking the Coach button. For example "930-1080L"
   */
  "learning-app-2-level"?: string;
  /**
   * The number of skills mastered by student on the secondary app for the subject they've clicked the Coach button on.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "learning-app-2-mastered"?: number;
  /**
   * The tertiary learning app used by student for the subject they've clicked the Coach button on. Typically this will be IXL or Khan etc.
   */
  "learning-app-3"?: string;
  /**
   * The tertiary learning app's level the student is on when clicking the Coach button. For example "930-1080L"
   */
  "learning-app-3-level"?: string;
  /**
   * The number of skills mastered by student on the tertiary app for the subject they've clicked the Coach button on.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "learning-app-3-mastered"?: number;
  /**
   * The subject that the link is associated with, i.e. Math, Science etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Math, Science, Reading, Writing, Language, Social Studies, Computer Science, English, Economics, Non-Core Skills, SAT Prep, American History, Biology, Calculus, Chemistry, Foreign Language, Physics, Statistics, Test Prep, World History, World Languages |
   */
  subject?:
    | "Math"
    | "Science"
    | "Reading"
    | "Writing"
    | "Language"
    | "Social Studies"
    | "Computer Science"
    | "English"
    | "Economics"
    | "Non-Core Skills"
    | "SAT Prep"
    | "American History"
    | "Biology"
    | "Calculus"
    | "Chemistry"
    | "Foreign Language"
    | "Physics"
    | "Statistics"
    | "Test Prep"
    | "World History"
    | "World Languages";
  /**
   * The time spent (in minutes) by student for the subject they've clicked the Coach button on.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "time-spent"?: number;
}

export interface ClickLearningLinkProperties {
  /**
   * The dash instance the student is using
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Esports, Alpha, Alpha Anywhere, Learn and Earn, Havruta |
   */
  dash: "Esports" | "Alpha" | "Alpha Anywhere" | "Learn and Earn" | "Havruta";
  /**
   * The student's current grade
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | K, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12 |
   */
  grade: "K" | "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9" | "10" | "11" | "12";
  /**
   * The primary learning app used by student for the subject they've clicked the Coach button on. Typically this will be IXL or Khan etc.
   */
  "learning-app-1"?: string;
  /**
   * The type of learning link that is clicked, typically this will either be a Skill or Suggested Content
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Skill, Suggested Content |
   */
  "learning-link": "Skill" | "Suggested Content";
  /**
   * The current SmartScore displayed when the link was clicked, typically between 0 and 100
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   * | Max Value | 100 |
   */
  score?: number;
  /**
   * The name displayed in the Skill link, generally this will be the Skill's title
   */
  "skill-name"?: string;
  /**
   * The type of Source listed in the row of the link that was clicked. This is typically Skill Plan
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Skill Plan |
   */
  source?: "Skill Plan";
  /**
   * The subject that the link is associated with, i.e. Math, Science etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Math, Science, Reading, Writing, Language, Social Studies, Computer Science, English, Economics, Non-Core Skills, SAT Prep, American History, Biology, Calculus, Chemistry, Foreign Language, Physics, Statistics, Test Prep, World History, World Languages |
   */
  subject?:
    | "Math"
    | "Science"
    | "Reading"
    | "Writing"
    | "Language"
    | "Social Studies"
    | "Computer Science"
    | "English"
    | "Economics"
    | "Non-Core Skills"
    | "SAT Prep"
    | "American History"
    | "Biology"
    | "Calculus"
    | "Chemistry"
    | "Foreign Language"
    | "Physics"
    | "Statistics"
    | "Test Prep"
    | "World History"
    | "World Languages";
  /**
   * This property is a boolean where if True  the clicked Skill link also had a Suggested Content link
   */
  "suggested-content"?: boolean;
  /**
   * The link text used in the Suggested Content link that was clicked. Typically this will be the title of the YouTube etc content suggested
   */
  "suggested-content-name"?: string;
}

export interface ClickLoginProperties {
  /**
   * The dash instance the student is using
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Esports, Alpha, Alpha Anywhere, Learn and Earn, Havruta |
   */
  dash: "Esports" | "Alpha" | "Alpha Anywhere" | "Learn and Earn" | "Havruta";
  /**
   * The login method the student is using to log in, typically this will be either email or Google SSO
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | email, Google SSO |
   */
  "login-method": "email" | "Google SSO";
}

export interface ClickMenuLinkProperties {
  /**
   * The current view the student is looking at when they click to change view
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Daily, Weekly, Monthly, Profile, My Assigned Learning |
   */
  "current-view"?: "Daily" | "Weekly" | "Monthly" | "Profile" | "My Assigned Learning" | "My Goals";
  /**
   * The dash instance the student is using
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Esports, Alpha, Alpha Anywhere, Learn and Earn, Havruta |
   */
  dash: "Esports" | "Alpha" | "Alpha Anywhere" | "Learn and Earn" | "Havruta";
  /**
   * The student's current grade
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | K, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12 |
   */
  grade: "K" | "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9" | "10" | "11" | "12";
  /**
   * This property highlights which menu the menu link was housed in, ie the Side Menu or the Avatar Menu
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Avatar Menu, Side Menu |
   */
  menu: "Avatar Menu" | "Side Menu";
  /**
   * This string is the name of the link clicked in the menu, for example Logout
   */
  "menu-link": string;
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(
    public event_properties?: IdentifyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AmplitudeElementChanged implements BaseEvent {
  event_type = '[Amplitude] Element Changed';

  constructor(
    public event_properties?: AmplitudeElementChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AmplitudeElementClicked implements BaseEvent {
  event_type = '[Amplitude] Element Clicked';

  constructor(
    public event_properties?: AmplitudeElementClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AmplitudeFormStarted implements BaseEvent {
  event_type = '[Amplitude] Form Started';

  constructor(
    public event_properties?: AmplitudeFormStartedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AmplitudeFormSubmitted implements BaseEvent {
  event_type = '[Amplitude] Form Submitted';

  constructor(
    public event_properties?: AmplitudeFormSubmittedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AmplitudePageViewed implements BaseEvent {
  event_type = '[Amplitude] Page Viewed';

  constructor(
    public event_properties?: AmplitudePageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickActivityView implements BaseEvent {
  event_type = 'clickActivityView';

  constructor(
    public event_properties: ClickActivityViewProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickCoaching implements BaseEvent {
  event_type = 'clickCoaching';

  constructor(
    public event_properties: ClickCoachingProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickLearningLink implements BaseEvent {
  event_type = 'clickLearningLink';

  constructor(
    public event_properties: ClickLearningLinkProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickLogin implements BaseEvent {
  event_type = 'clickLogin';

  constructor(
    public event_properties: ClickLoginProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickMenuLink implements BaseEvent {
  event_type = 'clickMenuLink';

  constructor(
    public event_properties: ClickMenuLinkProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SessionStart implements BaseEvent {
  event_type = 'session_start';
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * [Amplitude] Element Changed
   *
   * [View in Tracking Plan](https://data.amplitude.com/alphaschool/Alpha/events/main/latest/%5BAmplitude%5D%20Element%20Changed)
   *
   * This event tracks when a general element has been changed within the application
   *
   * Owner: Miguel Oliveira
   *
   * @param properties The event's properties (e.g. [Amplitude] Element Class)
   * @param options Amplitude event options.
   */
  amplitudeElementChanged(
    properties?: AmplitudeElementChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AmplitudeElementChanged(properties), options);
  }

  /**
   * [Amplitude] Element Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/alphaschool/Alpha/events/main/latest/%5BAmplitude%5D%20Element%20Clicked)
   *
   * Owner: Miguel Oliveira
   *
   * @param properties The event's properties (e.g. [Amplitude] Element Class)
   * @param options Amplitude event options.
   */
  amplitudeElementClicked(
    properties?: AmplitudeElementClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AmplitudeElementClicked(properties), options);
  }

  /**
   * [Amplitude] Form Started
   *
   * [View in Tracking Plan](https://data.amplitude.com/alphaschool/Alpha/events/main/latest/%5BAmplitude%5D%20Form%20Started)
   *
   * This event tracks when a user starts filling out a form on our platform
   *
   * Owner: Miguel Oliveira
   *
   * @param properties The event's properties (e.g. [Amplitude] Form Destination)
   * @param options Amplitude event options.
   */
  amplitudeFormStarted(
    properties?: AmplitudeFormStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AmplitudeFormStarted(properties), options);
  }

  /**
   * [Amplitude] Form Submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/alphaschool/Alpha/events/main/latest/%5BAmplitude%5D%20Form%20Submitted)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. [Amplitude] Form Destination)
   * @param options Amplitude event options.
   */
  amplitudeFormSubmitted(
    properties?: AmplitudeFormSubmittedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AmplitudeFormSubmitted(properties), options);
  }

  /**
   * [Amplitude] Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/alphaschool/Alpha/events/main/latest/%5BAmplitude%5D%20Page%20Viewed)
   *
   * This event tracks when a user views a page on our website or app. It provides a general overview of page views across all pages
   *
   * Owner: Miguel Oliveira
   *
   * @param properties The event's properties (e.g. [Amplitude] Page Domain)
   * @param options Amplitude event options.
   */
  amplitudePageViewed(
    properties?: AmplitudePageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AmplitudePageViewed(properties), options);
  }

  /**
   * clickActivityView
   *
   * [View in Tracking Plan](https://data.amplitude.com/alphaschool/Alpha/events/main/latest/clickActivityView)
   *
   * This event fires when user changes the view of the Activity area, for example switching from Daily to Weekly view
   *
   *  View on Miro [here](https://miro.com/app/board/uXjVNDPwyB0=/?moveToWidget=3458764576015336015&cot=14)
   *
   * Owner: Chris Prescott
   *
   * @param properties The event's properties (e.g. 2hr-subject)
   * @param options Amplitude event options.
   */
  clickActivityView(
    properties: ClickActivityViewProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickActivityView(properties), options);
  }

  /**
   * clickCoaching
   *
   * [View in Tracking Plan](https://data.amplitude.com/alphaschool/Alpha/events/main/latest/clickCoaching)
   *
   * The Coaching button diplayed on the Activity views
   *
   *  View on Miro [here](https://miro.com/app/board/uXjVNDPwyB0=/?moveToWidget=3458764576015100386&cot=14)
   *
   * Owner: Chris Prescott
   *
   * @param properties The event's properties (e.g. accuracy)
   * @param options Amplitude event options.
   */
  clickCoaching(
    properties: ClickCoachingProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickCoaching(properties), options);
  }

  /**
   * clickLearningLink
   *
   * [View in Tracking Plan](https://data.amplitude.com/alphaschool/Alpha/events/main/latest/clickLearningLink)
   *
   * This event fires when any link in My Assigned Learning is clicked.
   *
   *
   * View on Miro [here](https://miro.com/app/board/uXjVNDPwyB0=/?moveToWidget=3458764576014779356&cot=14)
   *
   * Owner: Chris Prescott
   *
   * @param properties The event's properties (e.g. dash)
   * @param options Amplitude event options.
   */
  clickLearningLink(
    properties: ClickLearningLinkProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickLearningLink(properties), options);
  }

  /**
   * clickLogin
   *
   * [View in Tracking Plan](https://data.amplitude.com/alphaschool/Alpha/events/main/latest/clickLogin)
   *
   * This event fires when the user clicks to login, using either Google SSO or email
   *
   * View on Miro [here](https://miro.com/app/board/uXjVNDPwyB0=/?moveToWidget=3458764576014673681&cot=14)
   *
   * Owner: Chris Prescott
   *
   * @param properties The event's properties (e.g. dash)
   * @param options Amplitude event options.
   */
  clickLogin(
    properties: ClickLoginProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickLogin(properties), options);
  }

  /**
   * clickMenuLink
   *
   * [View in Tracking Plan](https://data.amplitude.com/alphaschool/Alpha/events/main/latest/clickMenuLink)
   *
   * This event fires when the user clicks a link in either the side menu or the avatar menu
   *
   * Owner: Chris Prescott
   *
   * @param properties The event's properties (e.g. current-view)
   * @param options Amplitude event options.
   */
  clickMenuLink(
    properties: ClickMenuLinkProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickMenuLink(properties), options);
  }

  /**
   * session_start
   *
   * [View in Tracking Plan](https://data.amplitude.com/alphaschool/Alpha/events/main/latest/session_start)
   *
   * This event signifies the start of a user session on our platform. It captures the initial interaction of a user with our application, marking the beginning of their engagement
   *
   * Owner: Miguel Oliveira
   *
   * @param options Amplitude event options.
   */
  sessionStart(
    options?: EventOptions,
  ) {
    return this.track(new SessionStart(), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
