import { API } from '@aws-amplify/api';
import { PostCoachingAntiPattern } from 'common/@types/test-coaching';

export async function getPostCoachingAntiPattern(): Promise<PostCoachingAntiPattern[]> {
  try {
    const apiName = process.env.NEXT_PUBLIC_ACADEMICS_API_NAME!;
    const path = `api/post-coaching-anti-pattern`;
    const myInit = {};
    const response = await API.get(apiName, path, myInit);
    return response.result;
  } catch (error) {
    console.error(error);
    throw error;
  }
}