import { API } from 'aws-amplify';

export async function getClipReviewPrompt(input: { id: string }): Promise<string> {
  try {
    const apiName = process.env.NEXT_PUBLIC_ACADEMICS_API_NAME!;
    const path = `api/clip_review/prompt`;
    const myInit = {
      headers: {},
      queryStringParameters: {
        id: input.id,
      },
    };
    return await API.get(apiName, path, myInit);
  } catch (error) {
    console.error('Error fetching skill plan:', error);
    throw error;
  }
}
