import { API } from '@aws-amplify/api';

export async function deleteAntipattern({
  antipatternId,
}: {
  antipatternId: string;
}): Promise<void> {
  try {
    const apiName = process.env.NEXT_PUBLIC_ACADEMICS_API_NAME!;
    const path = `api/antipatterns/${antipatternId}`;
    const myInit = {};

    await API.del(apiName, path, myInit);
  } catch (error) {
    console.error(error);
    throw error;
  }
}
