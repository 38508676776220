import * as mixpanel from 'mixpanel-browser';
import { useEffect, useRef } from 'react';

export class MixpanelService implements mixpanel.Mixpanel {
  private readonly shouldTrack: boolean =
    process.env.NEXT_PUBLIC_ENV === process.env.NEXT_PUBLIC_MIXPANEL_ENV_TO_TRACK;

  // @ts-ignore
  init(
    token: string,
    config: Partial<mixpanel.Config>,
    name?: string
  ): mixpanel.Mixpanel | undefined {
    if (!this.shouldTrack) {
      return;
    }

    mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN as string, config);
  }

  identify(unique_id?: string): any {
    if (!this.shouldTrack) {
      return;
    }

    mixpanel.identify(unique_id);
  }

  track(
    event_name: string,
    properties?: mixpanel.Dict,
    optionsOrCallback?: mixpanel.RequestOptions | mixpanel.Callback,
    callback?: mixpanel.Callback
  ) {
    if (!this.shouldTrack) {
      return;
    }

    mixpanel.track(event_name, properties, optionsOrCallback, callback);
  }
}

// Export a single instance of MixpanelService
export const mixpanelService = new MixpanelService();

export function useTrackViewSwitch(view: string, setView: (view: string) => void) {
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      const viewName = view === 'false' ? '2x' : '2hr';
      mixpanelService.track('View Switched', { view: view, sessionType: viewName });
    }
  }, [view]);

  return setView;
}
