import { API } from 'aws-amplify';
import { TwoHourOnboardingStatus } from 'common/@types/recommendations';

export async function getTwoHourOnboardingStatus(input: {
  id: string;
}): Promise<TwoHourOnboardingStatus> {
  if (!input.id)
    return {
      student_id: '',
      onboarding_type: '',
      onboarding_stage: 'recommendations',
      khan_account_created: false,
      khan_math_class_link: null,
      original_age_grade: 0,
      created_on: '',
      updated_on: '',
    };
  try {
    const apiName = process.env.NEXT_PUBLIC_DASH_API_NAME!;
    const path = `api/onboarding/twohour/status`;
    const myInit = {
      headers: {},
      queryStringParameters: {
        student_id: input.id,
      },
    };
    return await API.get(apiName, path, myInit);
  } catch (error) {
    console.error('Error fetching TwoHour onboarding status:', error);
    throw error;
  }
}
