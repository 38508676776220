import { useSnackbar } from 'notistack';
import { useState } from 'react';
// next
import NextLink from 'next/link';
// @mui
import { alpha, useTheme } from '@mui/material/styles';
import { Box, Divider, MenuItem, Stack, Typography } from '@mui/material';
// hooks
import useAuth from '@common/hooks/useAuth';
import useIsMountedRef from '@common/hooks/useIsMountedRef';
// components
import MyAvatar from '@common/components/MyAvatar';
import MenuPopover from '@common/components/MenuPopover';
import { IconButtonAnimate } from '@common/components/animate';
import useLayoutContext from '@common/hooks/useLayoutContext';
import {
  CurrentViewType,
  amplitudeService,
  getCampusTypeByDomain,
} from 'common/analytics/amplitude';
import useSiteConfig from 'common/hooks/useSiteConfig';

// ----------------------------------------------------------------------

type Props = {
  currentView?: CurrentViewType;
};

export default function AccountPopover({ currentView }: Props) {
  const { accountPopoverOptions } = useLayoutContext();
  const { user, logout } = useAuth();
  const theme = useTheme();

  const isMountedRef = useIsMountedRef();

  const { enqueueSnackbar } = useSnackbar();

  const siteConfig = useSiteConfig();

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const onClickLink = (linkName: string) => {
    if (siteConfig?.data?.domain) {
      const campus = getCampusTypeByDomain(siteConfig.data.domain);
      amplitudeService.trackClickMenuLink(campus, linkName, 'Avatar Menu', currentView);
    }
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    try {
      if (siteConfig?.data?.domain) {
        const campus = getCampusTypeByDomain(siteConfig.data.domain);
        amplitudeService.trackClickMenuLink(campus, 'Logout', 'Avatar Menu', currentView);
      }

      await logout();
      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.15),
            },
          }),
        }}
      >
        <MyAvatar />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.attributes['custom:customName']}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.attributes.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {accountPopoverOptions
            .filter((option) => option.blackListFunction == null || !option.blackListFunction(user))
            .map((option) => (
              <NextLink
                key={option.label}
                href={option.linkTo}
                passHref
                style={{
                  textDecoration: 'none',
                  color: theme.palette.text.secondary,
                }}
                onClick={() => onClickLink(option.label)}
              >
                <MenuItem key={option.label} onClick={handleClose}>
                  {option.label}
                </MenuItem>
              </NextLink>
            ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
