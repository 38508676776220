// ----------------------------------------------------------------------

export { default as NavSectionVertical } from './vertical';
export { default as NavSectionHorizontal } from './horizontal';

export function isExternalLink(path: string) {
  return path.includes('http');
}

export function getActive(path: string, pathname: string, asPath: string) {
  const checkPath = path.startsWith('#');
  const pathTruncated = path[path.length - 1] === '/' ? path.slice(0, -1) : path;

  return (!checkPath && pathname === pathTruncated) || (!checkPath && asPath === pathTruncated);
}
