import { forwardRef } from 'react';
// next
import NextLink from 'next/link';
// @mui
import { Box, Tooltip, Typography } from '@mui/material';
// hooks
import useLocales from '../../../hooks/useLocales';
import useLayoutContext from '@common/hooks/useLayoutContext';
// type
import { NavItemProps } from '@common/@types/nav';
//
import Iconify from '../../Iconify';
import { ListItemIconStyle, ListItemStyle, ListItemStyleProps, ListItemTextStyle } from './style';
import { isExternalLink } from '..';
import useSiteConfig from 'common/hooks/useSiteConfig';
import { amplitudeService, getCampusTypeByDomain } from 'common/analytics/amplitude';

// ----------------------------------------------------------------------

// HANDLE SHOW ITEM BY ROLE
const ListItem = forwardRef<HTMLDivElement & HTMLAnchorElement, ListItemStyleProps>(
  (props, ref) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { ...other } = props;
    return (
      <ListItemStyle {...other} ref={ref}>
        {props.children}
      </ListItemStyle>
    );
  }
);

export function NavItemRoot({
  item,
  isCollapse,
  open = false,
  active,
  onOpen,
  currentView,
}: NavItemProps) {
  const { translate } = useLocales();
  const { getTypedGuard } = useLayoutContext();
  const BlacklistedAttributeGuard = getTypedGuard('BlacklistedAttributeGuard');

  const { title, path, icon, info, children, disabled, caption, blacklistFunction } = item;
  const siteConfig = useSiteConfig();

  const onClickLink = (linkName: string) => {
    if (siteConfig?.data?.domain) {
      const campus = getCampusTypeByDomain(siteConfig.data.domain);
      amplitudeService.trackClickMenuLink(campus, linkName, 'Side Menu', currentView);
    }
  };

  const renderContent = (
    <>
      {icon && <ListItemIconStyle>{icon}</ListItemIconStyle>}
      <ListItemTextStyle
        disableTypography
        primary={translate(title)}
        secondary={
          <Tooltip title={translate(caption) || ''} arrow>
            <Typography
              noWrap
              variant="caption"
              component="div"
              sx={{ textTransform: 'initial', color: 'text.secondary' }}
            >
              {translate(caption)}
            </Typography>
          </Tooltip>
        }
        isCollapse={isCollapse}
      />
      {!isCollapse && (
        <>
          {info && info}
          {children && <ArrowIcon open={open} />}
        </>
      )}
    </>
  );

  return (
    <BlacklistedAttributeGuard blacklistFunction={blacklistFunction} path={path}>
      {children ? (
        <ListItem onClick={onOpen} activeRoot={active} disabled={disabled}>
          {renderContent}
        </ListItem>
      ) : isExternalLink(path) ? (
        <NextLink
          onClick={() => onClickLink(title)}
          href={path}
          passHref
          style={{ textDecoration: 'none' }}
        >
          <ListItem disabled={disabled}>{renderContent}</ListItem>
        </NextLink>
      ) : (
        <NextLink
          onClick={() => onClickLink(title)}
          href={path}
          passHref
          style={{ textDecoration: 'none' }}
        >
          <ListItem activeRoot={active} disabled={disabled}>
            {renderContent}
          </ListItem>
        </NextLink>
      )}
    </BlacklistedAttributeGuard>
  );
}

// ----------------------------------------------------------------------

export function NavItemSub({
  item,
  open = false,
  active = false,
  isCollapse,
  onOpen,
  currentView,
}: NavItemProps) {
  const { translate } = useLocales();
  const { getTypedGuard } = useLayoutContext();
  const siteConfig = useSiteConfig();
  const BlacklistedAttributeGuard = getTypedGuard('BlacklistedAttributeGuard');

  const { title, path, icon, info, children, disabled, caption, blacklistFunction } = item;

  const onClickLink = (linkName: string) => {
    if (siteConfig?.data?.domain) {
      const campus = getCampusTypeByDomain(siteConfig.data.domain);
      amplitudeService.trackClickMenuLink(campus, linkName, 'Side Menu', currentView);
    }
  };

  const renderContent = (
    <>
      {icon ? (
        <Box marginLeft={0.5} sx={{ ...(!isCollapse && { marginRight: 2.5 }) }}>
          {icon}
        </Box>
      ) : (
        <DotIcon active={active} />
      )}
      {!isCollapse && (
        <>
          <ListItemTextStyle
            disableTypography
            primary={translate(title)}
            secondary={
              <Tooltip title={translate(caption) || ''} arrow>
                <Typography
                  noWrap
                  variant="caption"
                  component="div"
                  sx={{ textTransform: 'initial', color: 'text.secondary' }}
                >
                  {translate(caption)}
                </Typography>
              </Tooltip>
            }
          />
          {info && info}
          {children && <ArrowIcon open={open} />}
        </>
      )}
    </>
  );

  return (
    <BlacklistedAttributeGuard blacklistFunction={blacklistFunction} path={path}>
      {children ? (
        <ListItem onClick={onOpen} activeSub={active} subItem disabled={disabled}>
          {renderContent}
        </ListItem>
      ) : isExternalLink(path) ? (
        <NextLink
          onClick={() => onClickLink(title)}
          href={path}
          passHref
          style={{ textDecoration: 'none' }}
        >
          <ListItem subItem disabled={disabled}>
            {renderContent}
          </ListItem>
        </NextLink>
      ) : (
        <NextLink
          onClick={() => onClickLink(title)}
          href={path}
          passHref
          style={{ textDecoration: 'none' }}
        >
          <ListItem activeSub={active} subItem disabled={disabled}>
            {renderContent}
          </ListItem>
        </NextLink>
      )}
    </BlacklistedAttributeGuard>
  );
}

// ----------------------------------------------------------------------

type DotIconProps = {
  active: boolean;
};

export function DotIcon({ active }: DotIconProps) {
  return (
    <ListItemIconStyle>
      <Box
        component="span"
        sx={{
          width: 4,
          height: 4,
          borderRadius: '50%',
          bgcolor: 'text.disabled',
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shorter,
            }),
          ...(active && {
            transform: 'scale(2)',
            bgcolor: 'primary.main',
          }),
        }}
      />
    </ListItemIconStyle>
  );
}

// ----------------------------------------------------------------------

type ArrowIconProps = {
  open: boolean;
};

export function ArrowIcon({ open }: ArrowIconProps) {
  return (
    <Iconify
      icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
      sx={{ width: 16, height: 16, ml: 1 }}
    />
  );
}
