import { API } from '@aws-amplify/api';

interface UpdateCoachingSummaryResponseInput {
  id: number;
  type: 'parent' | 'guide';
  response: 'agree' | 'disagree';
  email: string;
}

export async function updateCoachingSummaryResponse(
  input: UpdateCoachingSummaryResponseInput
): Promise<void> {
  try {
    const apiName = process.env.NEXT_PUBLIC_DASH_API_NAME!;
    const path = `api/summarized-coaching/response`;
    const myInit = {
      queryStringParameters: {},
      body: input,
    };

    await API.patch(apiName, path, myInit);
  } catch (error) {
    console.error(error);
    throw error;
  }
}
