// next
import NextLink from 'next/link';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
// hooks
import useAuth from '@common/hooks/useAuth';
// components
import MyAvatar from '@common/components/MyAvatar';
import {
  CurrentViewType,
  amplitudeService,
  getCampusTypeByDomain,
} from 'common/analytics/amplitude';
import useSiteConfig from 'common/hooks/useSiteConfig';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

type Props = {
  isCollapse: boolean | undefined;
  currentView?: CurrentViewType;
};

export default function NavbarAccount({ currentView, isCollapse }: Props) {
  const { user } = useAuth();
  const currentRoles = user?.authorization?.userfrontTenantId?.roles;

  const siteConfig = useSiteConfig(); // AI-GEN - Cursor - GPT4

  const onClickLink = (linkName: string) => {
    // AI-GEN - Cursor - GPT4
    if (siteConfig?.data?.domain) {
      // AI-GEN - Cursor - GPT4
      const campus = getCampusTypeByDomain(siteConfig.data.domain); // AI-GEN - Cursor - GPT4
      amplitudeService.trackClickMenuLink(campus, linkName, 'Side Menu', currentView); // AI-GEN - Cursor - GPT4
    } // AI-GEN - Cursor - GPT4
  }; // AI-GEN - Cursor - GPT4

  return (
    <NextLink
      href={process.env.ACCOUNT_CLICK_PATH as string}
      onClick={() => onClickLink('profile')} // AI-GEN - Cursor - GPT4
      passHref
      style={{ color: 'inherit', textDecoration: 'none' }}
    >
      <RootStyle
        sx={{
          ...(isCollapse && {
            bgcolor: 'transparent',
          }),
        }}
      >
        <MyAvatar />

        <Box
          sx={{
            ml: 2,
            transition: (theme) =>
              theme.transitions.create('width', {
                duration: theme.transitions.duration.shorter,
              }),
            ...(isCollapse && {
              ml: 0,
              width: 0,
            }),
          }}
        >
          <Typography variant="subtitle2" noWrap>
            {user?.attributes['custom:customName']}
          </Typography>
          <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
            {currentRoles}
          </Typography>
        </Box>
      </RootStyle>
    </NextLink>
  );
}
