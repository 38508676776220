import { createSlice } from '@reduxjs/toolkit';
// @types
import { SimpleCrudFilters } from '../../@types/crud';
//
import { dispatch } from '../store';
import dayjs from '@common/@types/dayjs-custom';
import { formatDayjs } from '@common/utils/formatTime';

const now = dayjs();
const today = formatDayjs(now);
const startDate = formatDayjs(now.subtract(1, 'day'));
const initialState: { filters: SimpleCrudFilters } = {
  filters: {
    campus_id: '',
    student_id: '',
    fromDate: startDate,
    toDate: today,
    subject: '',
    start: 0,
    number: 10,
  },
};

const slice = createSlice({
  name: 'crudFilters',
  initialState,
  reducers: {
    // it is this simple
    setFilters(state, action) {
      state.filters = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function setFilters(payload: SimpleCrudFilters) {
  return dispatch(slice.actions.setFilters(payload));
}
