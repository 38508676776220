import { API } from '@aws-amplify/api';
import { student_antipatterns } from '../../@types/prisma';

export async function getAntiPatterns({
  fromDate,
  toDate,
  studentIds,
}: {
  fromDate: string;
  toDate: string;
  studentIds: string[];
}): Promise<student_antipatterns[]> {
  try {
    const apiName = process.env.NEXT_PUBLIC_DASH_API_NAME!;
    const path = '/anti-pattern';
    const myInit = {
      headers: {},
      queryStringParameters: {
        startDate: fromDate,
        endDate: toDate,
        studentIds,
      },
    };
    const response = await API.get(apiName, path, myInit);
    return response.result.data.json;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
