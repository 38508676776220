import { format } from 'date-fns';
import dayjs from '@common/@types/dayjs-custom';

export function formatDayjs(date: dayjs.Dayjs) {
  if (dayjs.isDayjs(date)) {
    return date.format('YYYY-MM-DD');
  } else {
    throw new Error('Date is not in Dayjs format');
  }
}

export function formatDate(date: Date) {
  return format(date, 'yyyy-MM-dd');
}

export function dateStringToLocalTime(dateString: string): Date {
  const slicedDate = dateString.slice(0, 10);
  const parsedDate = dayjs(slicedDate, 'YYYY-MM-DD', true);
  if (parsedDate.isValid()) {
    return parsedDate.toDate();
  } else {
    return dayjs(dateString).toDate();
  }
}
