import { SiteConfig, SiteConfigBase } from '@common/@types/siteConfig';
import siteConfig from '@common/sites/site-config.json';

let cachedSiteConfig: SiteConfig | undefined;

export async function getSiteConfig(): Promise<SiteConfig | null> {
  if (cachedSiteConfig) {
    return cachedSiteConfig;
  }

  // Get domain and TLD but not subdomain, use that to index siteConfig
  let domain = '';
  if (typeof window !== 'undefined') domain = window.location.hostname;
  const siteConfigKey = domain.split('.').slice(-2).join('.');

  // Return the config if it was in the JSON
  if (typeof siteConfigKey === 'string' && siteConfigKey in siteConfig) {
    const config = (siteConfig as SiteConfigBase)[siteConfigKey as keyof SiteConfigBase];
    config.domain = siteConfigKey;
    cachedSiteConfig = config;
    return config;
  }

  // Otherwise, null
  return null;
}
