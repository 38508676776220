import { levelMappings, Level, LevelDisplayName, Role } from '../@types/auth';
import { getStudents } from './amplify/getStudents';

export default async function getLevels(
  role: Role,
  groupIds: number[],
  childStudentIds: string[]
): Promise<Level[]> {
  if (role === Role.STUDENT || role === Role.GUIDE || role === Role.ADMIN) {
    return [getLevelByGroupIds(groupIds)];
  } else {
    return getLevelByChildStudentIds(childStudentIds);
  }
}

export function getDistinctLevels(): { [key: string]: string } {
  const lookup: { [key: string]: string } = {};
  for (const level of levelMappings) {
    if (!level.rosterLevel) continue;
    lookup[level.rosterLevel as string] = level.displayName;
  }
  return lookup;
}

function getLevelByGroupIds(groupIds: number[]): Level {
  const matchedLevel = levelMappings.find((x) => {
    const hasMatchedGuideGroupId = groupIds.includes(x.guideGroupId);
    const hasMatchedStudentGroupId = x.studentGroupId ? groupIds.includes(x.studentGroupId) : false;
    const hasMatchedAdminGroupId = x.adminGroupId ? groupIds.includes(x.adminGroupId) : false;
    if ((hasMatchedGuideGroupId || hasMatchedAdminGroupId) && hasMatchedStudentGroupId) {
      throw new Error('Found user with both guide and student group ids');
    }
    return hasMatchedGuideGroupId || hasMatchedStudentGroupId || hasMatchedAdminGroupId;
  });
  if (matchedLevel) {
    return matchedLevel;
  } else {
    console.error(`No level found for groupIds: ${groupIds}`);
    return {
      displayName: LevelDisplayName.Multilevel,
      lookupString: '>=0',
      guideGroupId: 5,
      adminGroupId: 19,
    };
  }
}

async function getLevelByChildStudentIds(childStudentIds: string[]): Promise<Level[]> {
  const students = await getStudents({
    studentIds: childStudentIds,
  });
  const levels = levelMappings.filter(
    (level: Level) =>
      level.rosterLevel &&
      students.some((student) => student.alpha_level_short.startsWith(<string>level.rosterLevel))
  );

  if (levels) {
    return levels;
  } else {
    console.error(`No level found for parent with child student IDs: ${childStudentIds}`);
    return [
      {
        displayName: LevelDisplayName.Multilevel,
        lookupString: '>=0',
        guideGroupId: 5,
        adminGroupId: 19,
      },
    ];
  }
}
