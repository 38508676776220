import { API } from 'aws-amplify';
import { EdulasticTestResult } from './getStudentsForTestAssignment';

export interface UnifiedTestResult {
  student_id: number;
  full_name: string;
  campus: string;
  level: string;
  grade: string;
  subject: string;
  rit1: string | null;
  rit2: string | null;
  rit_grade_level: string | null;
  max_rit: string | null;
  max_staar_score: number | null;
  staar_grade_level: string | null;
  staar_test_version: string | null;
  latest_test_version: string | null;
  latest_test_score: number | null;
  latest_test_grade_level: string | null;
  latest_test_date: string | null;
  max_mastered_grade_level: string | null;
  diagnostic_score: number | null;
  subject_id: number;
}

export interface GetNextBestTestDataInput {
  campus: string;
  studentIds?: string[];
}
export interface GetNextBestTestDataOutput {
  recentEdulasticTests: EdulasticTestResult[];
  unifiedTestResults: UnifiedTestResult[];
}

export async function getNextBestTestData(
  input: GetNextBestTestDataInput
): Promise<GetNextBestTestDataOutput> {
  const apiName = process.env.NEXT_PUBLIC_ACADEMICS_API_NAME!;
  const path = `api/testing/get-next-best-test-data`;
  const myInit: { [key: string]: any } = {
    headers: {},
    queryStringParameters: {},
  };
  if (input.campus != null && input.campus !== '') {
    myInit.queryStringParameters.campus = input.campus;
  }
  if (input.studentIds != null && input.studentIds.length > 0) {
    myInit.queryStringParameters.studentIds = input.studentIds;
  }
  return await API.get(apiName, path, myInit);
}
