import { API } from '@aws-amplify/api';
import {
  Block,
  MyRecommendationsOutput,
  Recommendation,
  SubBlock,
} from '../../@types/recommendations';

export async function getStudentLearningActivity(input: {
  studentId: string;
  v2: boolean | undefined;
}): Promise<MyRecommendationsOutput> {
  try {
    if (!input.studentId) return { recommendations: [] };

    const apiName = process.env.NEXT_PUBLIC_DASH_API_NAME!;
    const path = `api/recommendations/v2/${input.studentId}/my-recommendations`;

    const myInit = {
      queryStringParameters: {},
    };

    const data: MyRecommendationsOutput = await API.get(apiName, path, myInit);

    const dataOut: MyRecommendationsOutput = {
      recommendations_title: data.recommendations_title ?? '',
      recommendations: [],
    };
    if (input.v2 == null || !input.v2) {
      // Temp logic to clean up backend response data
      const mathBlock = data.recommendations[0];
      for (const block of data.recommendations) {
        if (
          mathBlock.subBlocks.length > 0 &&
          (block.subject === 'Math 1' || block.subject === 'Math 2')
        ) {
          block.subBlocks.unshift(mathBlock.subBlocks[0]);
        }

        for (const subBlock of block.subBlocks) {
          for (const row of subBlock.rows) {
            row.suggested_content = row.suggested_content?.replace(/(\\r)?\\n/g, '\n');
          }
        }
      }
      // Temporarily: skip original math block
      dataOut.recommendations.push(
        data.recommendations[4],
        data.recommendations[1],
        data.recommendations[2],
        data.recommendations[3],
        data.recommendations[5]
      );
    } else {
      const orderedSubjects = ['Math', 'Reading', 'Language', 'Science', 'Writing'];
      dataOut.recommendations = data.recommendations
        .filter((block: Block) => block.subBlocks.length > 0)
        .sort((a: Block, b: Block) => {
          const indexA = orderedSubjects.indexOf(a.subject);
          const orderA = indexA >= 0 ? indexA : orderedSubjects.length;
          const indexB = orderedSubjects.indexOf(b.subject);
          const orderB = indexB >= 0 ? indexB : orderedSubjects.length;
          if (orderA === orderB) {
            return 0;
          } else {
            return orderA < orderB ? -1 : 1;
          }
        });
      dataOut.recommendations.forEach((block: Block) => {
        block.subBlocks.forEach((subBlock: SubBlock) => {
          subBlock.rows.sort((a: Recommendation, b: Recommendation) => {
            if (a.priority < b.priority) return -1;
            if (a.priority > b.priority) return 1;
            return 0;
          });
        });
      });
    }

    return dataOut;
  } catch (error) {
    // Handle any errors that occur during the API call
    console.error(error);
    throw error;
  }
}
