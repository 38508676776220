import { API } from '@aws-amplify/api';
import { PostAppealInput } from '../../@types/appeals';

export async function postAppeal(input: PostAppealInput): Promise<void> {
  try {
    const apiName = process.env.NEXT_PUBLIC_DASH_API_NAME!;
    const path = 'api/appeals';
    const myInit = {
      queryStringParameters: {},
      body: input,
    };

    await API.post(apiName, path, myInit);
  } catch (error) {
    console.error(error);
    throw error;
  }
}
