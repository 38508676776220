import { API } from '@aws-amplify/api';
import { Role } from '../../@types/auth';
import getRole from '../getRole';
import { CognitoUser, UserListUser } from '../../@types/user';

export async function getUserList(): Promise<UserListUser[]> {
  try {
    const apiName = process.env.NEXT_PUBLIC_DASH_API_NAME!;
    const path = 'get-user-list';
    const response: CognitoUser[] = await API.get(apiName, path, {});

    // Convert CognitoUser to UserListUser
    const returnUsers = response.map((user: CognitoUser) => {
      const group = user.groups?.length > 0 ? user.groups[0] : 0;
      const outputUser: UserListUser = {
        name: user.name,
        email: user.email,
        image: user.image,
        verified: user.verified,
        group,
        level: user.level,
        campusId: user.campusId,
        role: getRole(group?.toString()),
        studentId: undefined,
        studentIds: undefined,
      };

      if (user.studentIds.length > 0) {
        outputUser.studentIds = user.studentIds;

        if (outputUser.role === Role.STUDENT && user.studentIds[0] !== '%') {
          outputUser.studentId = user.studentIds[0];
        }
      }

      return outputUser;
    });

    return returnUsers;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
