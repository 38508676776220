import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
// @mui
import { Collapse, List } from '@mui/material';
// type
import { NavListProps } from '@common/@types/nav';
//
import { NavItemRoot, NavItemSub } from './NavItem';
import { getActive } from '..';
import { CurrentViewType } from 'common/analytics/amplitude';
import useSiteConfig from 'common/hooks/useSiteConfig';

// ----------------------------------------------------------------------

type NavListRootProps = {
  list: NavListProps;
  isCollapse: boolean;
  currentView?: CurrentViewType;
};

export function NavListRoot({ list, isCollapse, currentView }: NavListRootProps) {
  const { pathname, asPath } = useRouter();
  const { data: siteConfig } = useSiteConfig();

  const active = getActive(list.path, pathname, asPath);

  const [open, setOpen] = useState(active);

  useEffect(() => {
    setOpen(siteConfig?.nav?.expandByDefault || active);
  }, [siteConfig?.nav?.expandByDefault, active]);

  const hasChildren = list.children;

  if (hasChildren) {
    return (
      <>
        <NavItemRoot
          item={list}
          isCollapse={isCollapse}
          active={active}
          open={open}
          onOpen={() => setOpen(!open)}
          currentView={currentView}
        />

        {(!isCollapse || siteConfig?.nav?.expandByDefault) && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {(list.children || []).map((item) => (
                <NavListSub
                  key={item.title + item.path}
                  list={item}
                  currentView={currentView}
                  isCollapse={isCollapse}
                />
              ))}
            </List>
          </Collapse>
        )}
      </>
    );
  }

  return (
    <NavItemRoot item={list} active={active} isCollapse={isCollapse} currentView={currentView} />
  );
}

// ----------------------------------------------------------------------

type NavListSubProps = {
  list: NavListProps;
  currentView?: CurrentViewType;
  isCollapse: boolean;
};

function NavListSub({ list, currentView, isCollapse }: NavListSubProps) {
  const { pathname, asPath } = useRouter();

  const active = getActive(list.path, pathname, asPath);

  const [open, setOpen] = useState(active);

  const hasChildren = list.children;

  if (hasChildren) {
    return (
      <>
        <NavItemSub
          currentView={currentView}
          item={list}
          onOpen={() => setOpen(!open)}
          open={open}
          active={active}
          isCollapse={isCollapse}
        />

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ pl: 3 }}>
            {(list.children || []).map((item) => (
              <NavItemSub
                key={item.title + item.path}
                item={item}
                active={getActive(item.path, pathname, asPath)}
                isCollapse={isCollapse}
              />
            ))}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <NavItemSub currentView={currentView} item={list} active={active} isCollapse={isCollapse} />
  );
}
