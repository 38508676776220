import { API } from 'aws-amplify';
import { Skill } from '@common/@types/recommendations';

export async function getAllSkills(): Promise<Skill[]> {
  try {
    const apiName = process.env.NEXT_PUBLIC_ACADEMICS_API_NAME!;
    const path = `api/skills`;
    const myInit = {
      headers: {},
      queryStringParameters: {},
    };
    const response: { results: Skill[] } = await API.get(apiName, path, myInit);
    return response.results;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
