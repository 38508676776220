import { API } from 'aws-amplify'; // AI-GEN - Cursor - GPT4

export interface StudentGoalsInput {
  studentId: string;
  subject: string;
}

export interface StudentGoal {
  id: number;
  code: string;
  description: string;
  applicable: boolean;
  targetGrade: number;
  lessonsRemaining: number | null;
  achieved?: boolean;
}

export interface StudentGoalsOutput {
  isSummerApplicable: boolean;
  daysUntilEndOfWeek: number;
  endOfWeek: string;
  daysUntilEndOfSession: number;
  endOfSession: string;
  daysUntilEndOfMonth: number;
  endOfMonth: string;
  daysUntilEndOfSchoolYear: number;
  endOfSchoolYear: string;
  currentGrade: number;
  goals: StudentGoal[];
}

export async function getStudentGoalTargets(input: {
  studentId: string;
  subject: string;
}): Promise<StudentGoalsOutput> {
  // AI-GEN - Cursor - GPT4
  try {
    // AI-GEN - Cursor - GPT4
    const apiName = process.env.NEXT_PUBLIC_DASH_API_NAME!; // AI-GEN - Cursor - GPT4
    const path = `student-goal-targets`; // AI-GEN - Cursor - GPT4
    const myInit = {
      // AI-GEN - Cursor - GPT4
      headers: {}, // AI-GEN - Cursor - GPT4
      queryStringParameters: { studentId: input.studentId, subject: input.subject }, // AI-GEN - Cursor - GPT4
    }; // AI-GEN - Cursor - GPT4
    const response = await API.get(apiName, path, myInit); // AI-GEN - Cursor - GPT4
    return response.result.data.json;
  } catch (error) {
    // AI-GEN - Cursor - GPT4
    console.error('Error fetching student goal targets:', error); // AI-GEN - Cursor - GPT4
    throw error; // AI-GEN - Cursor - GPT4
  } // AI-GEN - Cursor - GPT4
} // AI-GEN - Cursor - GPT4
