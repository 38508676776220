export type SiteTheme = {
  loadingLogoOpacity: number;
  zendesk: string;
  cta: {
    primary: string;
    secondary: string;
    tertiary?: string;
  };
  ctaBg: {
    primary: string;
  };
  toggleButton: {
    primary: string;
  };
  buttonPrimary?: {
    main?: string;
    dark?: string;
    contrastText?: string;
  };
  navbar: {
    activeSub: {
      selectedOpacity: number;
      background: string;
      color: string;
    };
    activeRoot: {
      selectedOpacity: number;
      background: string;
      color: string;
    };
    hoverSub?: {
      background?: string;
      color?: string;
    };
    hoverRoot?: {
      background?: string;
      color?: string;
    };
    background?: string;
    color?: string;
  };
  buttonSecondary?: {
    main?: string;
    dark?: string;
    contrastText?: string;
  };
  subjectBg: {
    language: string;
    reading: string;
    science: string;
    math: string;
    writing: string;
    'social studies': string;
  };
  subjectText: {
    language: string;
    reading: string;
    science: string;
    math: string;
    writing: string;
    'social studies': string;
  };
  checkCharts: {
    background: {
      primary: string;
      dark: string;
    };
  };
};

type SiteThemes = {
  [key: string]: SiteTheme;
};

export const siteThemes: SiteThemes = {
  alpha: {
    loadingLogoOpacity: 1,
    zendesk: '#2196F3',
    cta: {
      primary: '#40B8D4',
      secondary: '#2196F3',
    },
    ctaBg: {
      primary: '#F2F9FB',
    },
    buttonPrimary: {
      dark: '#206F98',
      contrastText: '#FFFFFF',
    },
    buttonSecondary: {
      dark: '#206F98',
      main: '#40B8D4',
      contrastText: '#FFFFFF',
    },
    navbar: {
      activeRoot: {
        selectedOpacity: 0.1,
        background: '#40B8D4',
        color: '#40B8D4',
      },
      activeSub: {
        selectedOpacity: 0,
        background: '#40B8D4',
        color: '#212B36',
      },
    },
    toggleButton: {
      primary: '#41B8D4',
    },
    subjectBg: {
      language: '#FFFDE8',
      reading: '#F0F0F0',
      science: '#ECF8EF',
      math: '#EAF6F9',
      writing: '#EAF6F9',
      'social studies': '#EAF6F9',
    },
    subjectText: {
      language: '#40B8D4',
      reading: '#40B8D4',
      science: '#40B8D4',
      math: '#40B8D4',
      writing: '#40B8D4',
      'social studies': '#40B8D4',
    },
    checkCharts: {
      background: {
        primary: '#EAF6F9',
        dark: '#5cdeff',
      },
    },
  },
  esports: {
    loadingLogoOpacity: 0.2,
    zendesk: '#355F42',
    cta: {
      primary: '#74C15A',
      secondary: '#355F42',
      tertiary: '#194827',
    },
    ctaBg: {
      primary: '#F1FFED',
    },
    toggleButton: {
      primary: '#1E1E1E',
    },
    buttonPrimary: {
      dark: '#1E1E1E',
      contrastText: '#FFFFFF',
    },
    buttonSecondary: {
      dark: '#1E1E1E',
      main: '#74C15A',
    },
    navbar: {
      activeRoot: {
        selectedOpacity: 0.1,
        background: '#74C15A',
        color: '#74C15A',
      },
      activeSub: {
        selectedOpacity: 0,
        background: '#74C15A',
        color: '#212B36',
      },
    },
    subjectBg: {
      language: '#FFF5D9',
      reading: '#FFDEE9',
      science: '#E6FFFF',
      math: '#E0EEFF',
      writing: '#E1DEFF',
      'social studies': '#E1DEFF',
    },
    subjectText: {
      language: '#363226',
      reading: '#37282D',
      science: '#283232',
      math: '#292F37',
      writing: '#2c2837',
      'social studies': '#303344',
    },
    checkCharts: {
      background: {
        primary: '#e5ffde',
        dark: '#85ff5c',
      },
    },
  },
  havruta: {
    loadingLogoOpacity: 0.2,
    zendesk: '#355F42',
    cta: {
      primary: '#02B902',
      secondary: '#ebebeb',
      tertiary: '#194827',
    },
    navbar: {
      activeRoot: {
        selectedOpacity: 1,
        background: '#323232',
        color: '#fff',
      },
      activeSub: {
        selectedOpacity: 1,
        background: '#fff',
        color: '#212B36',
      },
      hoverSub: {
        background: '#ebebeb',
        color: '#878d91',
      },
      hoverRoot: {
        background: '#ebebeb',
        color: '#878d91',
      },
      color: '#878d91',
    },
    ctaBg: {
      primary: '#EBEBEB',
    },
    toggleButton: {
      primary: '#1E1E1E',
    },
    buttonPrimary: {
      dark: '#01ad01', // hover bg
      contrastText: '#FFFFFF',
    },
    buttonSecondary: {
      dark: '#ebebeb', // hover bg
      main: '#ebebeb',
      contrastText: '#323232',
    },
    subjectBg: {
      language: '#FFF5D9',
      reading: '#FFDEE9',
      science: '#E6FFFF',
      math: '#E0EEFF',
      writing: '#E1DEFF',
      'social studies': '#E1DEFF',
    },
    subjectText: {
      language: '#363226',
      reading: '#37282D',
      science: '#283232',
      math: '#292F37',
      writing: '#2c2837',
      'social studies': '#303344',
    },
    checkCharts: {
      background: {
        primary: '#e5ffde',
        dark: '#85ff5c',
      },
    },
  },
};
