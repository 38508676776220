import { Auth } from '@aws-amplify/auth';
import { Amplify } from '@aws-amplify/core';
import { TOKEN_EXPIRED_EVENT } from '../constant';

Amplify.configure({
  API: {
    endpoints: [
      {
        name: process.env.NEXT_PUBLIC_DASH_API_NAME,
        endpoint: process.env.NEXT_PUBLIC_DASH_API_BASE,
        custom_header: async () => {
          try {
            const token = (await Auth.currentSession()).getIdToken().getJwtToken();
            return { 'x-amz-security-token': token };
          } catch (error) {
            window.dispatchEvent(new CustomEvent(TOKEN_EXPIRED_EVENT));
            console.log(error);
          }
        },
      },
      {
        name: process.env.NEXT_PUBLIC_ACADEMICS_API_NAME,
        endpoint: process.env.NEXT_PUBLIC_ACADEMICS_API_BASE,
        custom_header: async () => {
          try {
            const token = (await Auth.currentSession()).getIdToken().getJwtToken();
            return { 'x-amz-security-token': token };
          } catch (error) {
            window.dispatchEvent(new CustomEvent(TOKEN_EXPIRED_EVENT));
            console.log(error);
          }
        },
      },
    ],
  },
});
